import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';

const Search = ({ onSearchChange, placeholder }) => {
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);  
    setDebouncedSearch(value);  
    setTimeout(() => {
      onSearchChange(value);  
    }, 1000);
  };

  return (
    <div className="search-input-filter d-flex align-items-center">
      <div style={{ position: 'relative', width: 'max-content' }}>
      <input
        type="text"
        name="search"
        placeholder={placeholder}  
        className="offdK-search ps-3"
        onChange={handleSearchChange}
        value={search}
      />
      <FaSearch size={20} color="#215B5B" />
      </div>
    </div>
  );
};

export default Search;
