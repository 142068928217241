import React, { useState, useEffect } from 'react';

const DateFilter = ({ setStartDate, setEndDate, initialStartDate, initialEndDate }) => {
  const [localStartDate, setLocalStartDate] = useState(initialStartDate);
  const [localEndDate, setLocalEndDate] = useState(initialEndDate);
  const [maxDate, setMaxDate] = useState('');

  useEffect(() => {
    const today = new Date();
    setMaxDate(today.toISOString().split('T')[0]);
  }, []);

  useEffect(() => {
    setLocalStartDate(initialStartDate);
    setLocalEndDate(initialEndDate);
  }, [initialStartDate, initialEndDate]);

  const handleStartDateChange = (e) => {
    const value = e.target.value;
    setLocalStartDate(value);
    setStartDate(value);
  };

  const handleEndDateChange = (e) => {
    const value = e.target.value;
    setLocalEndDate(value);
    setEndDate(value);
  };

  return (
    <div className="date-filter">
  <div style={{ position: 'relative', width: 'fit-content' }}>
    <input
      type="date"
      value={localStartDate}
      max={maxDate}
      onChange={handleStartDateChange}
      style={{
        width: '180px',
        padding: '10px 15px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        background: 'transparent',
        fontSize: '14px',
        color: localStartDate ? '#333' : 'transparent',
        outline: 'none',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      }}
    />
    <label
      style={{
        position: 'absolute',
        top: '50%',
        left: '15px',
        transform: 'translateY(-50%)',
        color: '#999',
        fontSize: '14px',
        pointerEvents: 'none',
        transition: '0.2s ease all',
        zIndex: localStartDate ? '-1' : '1',
      }}
    >
      From
    </label>
   
  </div>
  <div style={{ position: 'relative', width: 'fit-content' }}>
    <input
      type="date"
      value={localEndDate}
      max={maxDate}
      onChange={handleEndDateChange}
      style={{
        width: '180px',
        padding: '10px 15px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        background: 'transparent',
        fontSize: '14px',
        color: localEndDate ? '#333' : 'transparent',
        outline: 'none',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      }}
    />
    <label
      style={{
        position: 'absolute',
        top: '50%',
        left: '15px',
        transform: 'translateY(-50%)',
        color: '#999',
        fontSize: '14px',
        pointerEvents: 'none',
        transition: '0.2s ease all',
        zIndex: localEndDate ? '-1' : '1',
      }}
    >
      To
    </label>
  </div>
    </div>
  );
};

export default DateFilter;
