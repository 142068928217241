import React, { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Menus from "../../component/Menu";
import { useTranslation } from "react-i18next";
import Footer from "../../component/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthUserContext } from "../../context/context";
import Spinner from "react-bootstrap/esm/Spinner";
import Navbars from "../../component/Navbar/Navbar";
import getPackagesDetails from "../../services/packages/getPackages";
import getTopupList from "../../services/topup/getTopupList";
import TopupCard from "../../component/TopupCard";
// import TopUpModal from "../../component/TopUpModal";
import CurrencyNameSelect from "../../component/CurrencyNameSelect";
import TopUpModal from "../../component/TopUpModal";
import SortSelect from "../../component/SortSelect";
import { CURRENCY } from "../../constants/localstorageKeys";
import { USER_DETAILS } from "../../constants/localstorageKeys";

import {
  countryCode,
  countryCurrency,
  currencySymbols,
} from "../../constants/currency";
import currencyExchange from "../../services/api/currencyyExchange";
import { countryList } from "../../constants/countryList";
import esimVaultSvg from "../../assets/images/esimVault.svg";
import airaloSvg from "../../assets/images/airalo.svg";
import esimgoSvg from "../../assets/images/esimgo.svg";
const TopUp = ({setEventTriggered}) => {
  const {
    currentLocalization,
    setSelectData,
    selectData,
    setSelectDays,
    selectDay,
    setSelectPrice,
    selectPrice,
    countryCodes,
    setToastId
  } = useContext(AuthUserContext);
  const [exchangeRate, setExchangeRate] = useState(1);
  const [user, setUser] = useState();
  const [currencySymbol, setCurrencySymbol] = useState("$");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const getCurrency = localStorage.getItem(CURRENCY);
  const { t } = useTranslation();
  const [eSimDetails, setEsimDetails] = useState([]);
  const [filterDetails, setFilterDetails] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [simRecordLength, setSimRecordLength] = useState(0);
  const [getCountryss, setGetCountrys] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [multipleRecord, setMultipleRecord] = useState([]);
  const [selected, setSelected] = useState(null);
  const [selectLoading, setSelectLoading] = useState(false);
  const [error, setError] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("USD");
  const [countryName, setCountryName] = useState(null);
  const [initialUpdate, setInitialUpdate] = useState(true);
  const [selectShowBaseOnRecord, setSelectShowBaseOnRecord] = useState(0);
  const [topuplistingSingle, setTopUpistingSingle] = useState("");
  const [selectValueShow, setSelectValueShow] = useState([]);
  const [priceSorting, setPriceSorting] = useState("");
  const [topUpSimSelected, setTopUpSimSelected] = useState(null);
  const handleCurrencyChange = ({ exchangeRate, currency }) => {
    setExchangeRate(exchangeRate);
    setCurrencySymbol(currency);
  };
  const getCountryDetails = async () => {
    try {
      const countryCode =
        selectedCountry && selectedCountry?.length > 0
          ? selectedCountry
          : countryName?.country_name;
      countryList?.forEach((element) => {
        if (element?.cca2 === countryCode) {
          setGetCountrys(element?.name?.common);
        }
      });
    } catch (e) {
      console.log("e", e);
    }
  };
  const getEsimList = async () => {
    try {
      setLoading(true);
      const response = await getPackagesDetails();
      let langs;
      if (currentLocalization === "in") {
        langs = "id";
      } else if (currentLocalization === "ar") {
        langs = "en";
      } else if (currentLocalization.startsWith("en-")) {
        langs = "en";
      } else {
        langs = currentLocalization;
      }
      setSelectShowBaseOnRecord(response.data?.length);
      if (response.data?.length === 0) {
        const id = toast.error(t("toastsMessage.part5"), {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
        navigate(`/country-filter?country=SA`);
      } else if (response.data?.length <= 1) {
        setSelected(response?.data[0]);
        const iccid = response?.data[0]?.sims[0]?.iccid;
        const vendorType = response?.data[0]?.vendorType;
        const topUpResponse = await getTopupList(iccid, vendorType, langs);
        setCountryName(topUpResponse?.countryDetais);
        setTopUpSimSelected(response?.data[0]);
        setTopUpistingSingle(topUpResponse?.countryDetais?.country_code);
        setSelectedCountry([topUpResponse?.countryDetais?.country_code]);
        setEsimDetails(topUpResponse?.topups);
        setSimRecordLength(topUpResponse?.topups?.length);
        setFilterDetails(topUpResponse?.topups);
        setInitialUpdate(false);
      } else {
        setMultipleRecord(response?.data);
        setModalShow(true);
      }
    } catch (err) {
      if (err.response?.status === 401) {
        const id = toast.error(t("toastsMessage.part4"), {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
        window.scroll(0, 0);
        navigate("/log-in");
      } else {
        console.log("err", err);
      }
    } finally {
      setLoading(false);
    }
  };
  const handleDataFilter = (data) => {
    try {
      setSelectData(data);
      applyFilters(data, selectDay, selectPrice);
    } catch (e) {
      console.log("Error processing", e);
    }
  };
  const handleDayFilter = (day) => {
    try {
      setSelectDays(day);
      applyFilters(selectData, day, selectPrice);
    } catch (e) {
      console.log("Error processing", e);
    }
  };
  const handlePriceFilter = (price) => {
    try {
      setSelectPrice(price);
      applyFilters(selectData, selectDay, price);
    } catch (e) {
      console.log("Error processing", e);
    }
  };
  const applyFilters = (data, day, price) => {
    let filteredData = eSimDetails;
    if (data !== "") {
      filteredData = filteredData.filter((item) => item.amount >= data);
    }
    if (day !== "") {
      filteredData = filteredData.filter((item) => item.validity >= day);
    }
    if (price !== "") {
      filteredData = filteredData.filter((item) => item.price <= price);
    }

    setSimRecordLength(filteredData?.length);
    setFilterDetails(filteredData);
  };
  const handleAllFilter = () => {
    try {
      setSelectData("");
      setSelectPrice("");
      setSelectDays("");
      setSimRecordLength(eSimDetails?.length);
      setFilterDetails(eSimDetails);
    } catch (e) {
      console.log("Error processing", e);
    }
  };

  const handleSelect = (item) => {
    setSelected(item);
    setError(false);
  };

  const selectPackage = async () => {
    try {
      if (selected === null) {
        setError(true);
        return;
      }

      setSelectLoading(true);
      const iccid = selected.sims[0]?.iccid;
      const vendorType = selected.vendorType;
      let langs;
      if (currentLocalization.startsWith("en-")) {
        langs = "en";
      } else {
        langs = currentLocalization;
      }
      const topUpResponse = await getTopupList(iccid, vendorType, langs);
      setTopUpSimSelected(selected);
      setCountryName(topUpResponse?.countryDetais);
      const selectCountry =
        topUpResponse?.countryDetais?.country_name === "World"
          ? "GB"
          : topUpResponse?.countryDetais?.country_code;
      setSelectedCountry([selectCountry]);
      setEsimDetails(topUpResponse?.topups);
      setSimRecordLength(topUpResponse?.topups?.length);
      setFilterDetails(topUpResponse?.topups);
      setModalShow(false);
      setSelectValueShow([selected]);
      // getCountryDetails()
    } catch (e) {
      console.log("error", e);
    } finally {
      setSelectLoading(false);
    }
  };
  const getPopUpList = async () => {
    try {
      setLoading(true);
      let countryCode =
        selectedCountry && selectedCountry?.length > 0 && selectedCountry;
      if (!Array.isArray(countryCode)) {
        countryCode = [countryCode];
      }
      const countriesParam = countryCode
        .map((country) => `country_code[]=${country}`)
        .join("&");
      const iccid = selected.sims[0]?.iccid;
      const vendorType = selected.vendorType;
      let langs;
      if (currentLocalization === "in") {
        langs = "id";
      } else if (currentLocalization === "ar") {
        langs = "en";
      } else if (currentLocalization.startsWith("en-")) {
        langs = "en";
      } else {
        langs = currentLocalization;
      }
      const topUpResponse = await getTopupList(
        iccid,
        vendorType,
        langs,
        countriesParam
      );
      if (priceSorting === "price") {
        let priceSort = topUpResponse?.topups?.sort(
          (a, b) => a.price - b.price
        );
        setFilterDetails(priceSort);
      } else if (priceSorting === "data") {
        let dataSort = topUpResponse?.topups?.sort(
          (a, b) => b.amount - a.amount
        );
        setFilterDetails(dataSort);
      } else if (priceSorting === "validity") {
        let validitySort = topUpResponse?.topups?.sort(
          (a, b) => b.validity - a.validity
        );
        setFilterDetails(validitySort);
      } else {
        setFilterDetails(topUpResponse?.topups);
      }
      setEsimDetails(topUpResponse?.topups);
      setSimRecordLength(topUpResponse?.topups?.length);
    } catch (e) {
      console.log("err", e);
    } finally {
      setLoading(false);
    }
  };
  const handleSorting = () => {
    if (priceSorting === "price") {
      const sortedESimDetails = [...eSimDetails].sort(
        (a, b) => a.price - b.price
      );
      setFilterDetails(sortedESimDetails);
    } else if (priceSorting === "data") {
      const sortedESimDetails = [...eSimDetails].sort(
        (a, b) => b.amount - a.amount
      );
      setFilterDetails(sortedESimDetails);
    } else if (priceSorting === "validity") {
      const sortedESimDetails = [...eSimDetails].sort(
        (a, b) => b.validity - a.validity
      );
      setFilterDetails(sortedESimDetails);
    } else {
      setFilterDetails(eSimDetails);
    }
  };
  const currencyCHange = async () => {
    try {
      if (getCurrency) {
        const newCode = countryCodes ? countryCodes : getCurrency;
        const getCountryCurrency = countryCurrency.find(
          (code) => code.currency_code === newCode
        );
        const country = countryCode.find(
          (country) => country.countryName === getCountryCurrency.country_code
        );
        const newCurrency = country ? country.countryCurrency : "USD";
        const currencyCode =
          currencySymbols.find((symbol) => symbol.Code === newCurrency)
            ?.Symbol ?? newCode;
        setCurrencySymbol(currencyCode);
        setSelectedCurrency(newCurrency);
        const response = await currencyExchange();
        const exchangeRate = response.rates[newCurrency];
        setExchangeRate(exchangeRate);
      }
    } catch (e) {
      console.log("e", e);
    }
  };
  useEffect(() => {
    currencyCHange();
  }, [getCurrency, countryCodes]);
  useEffect(() => {
    if (
      selectedCountry.length > 0 &&
      !(
        selectedCountry.length === 1 &&
        selectedCountry[0] === topuplistingSingle
      )
    ) {
      getPopUpList();
    } else {
      setTopUpistingSingle("");
    }
    getCountryDetails();
  }, [selectedCountry]);
  useEffect(() => {
    getEsimList();
  }, []);
  useEffect(() => {
    handleSorting();
  }, [priceSorting]);
  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
    if (userDetails) {
      setUser(userDetails);
    }
  }, []);
  return (
    <div>
      <Navbars />
      {topUpSimSelected && topUpSimSelected.vendorType !== "airalo" && (
        <Menus
          text={t("countryFilter.part1")}
          textGb={t("countryFilter.part2")}
          textWork={t("home.part16")}
          showSelect={!modalShow && true}
          setSelectedCountry={setSelectedCountry}
          selectedCountry={selectedCountry}
          country={countryName?.country_code ? countryName?.country_code : "GB"}
          getCountrys={
            getCountryss.length > 0 && t(`countryName.${getCountryss}`)
          }
          status="topup"
          loadings={loading}
          selectShowBaseOnRecord={selectShowBaseOnRecord}
          getCountryss={getCountryss}
        />
      )}

      <section className="offers-image">
        <Container>
          <Row
            className={`d-flex flex-row justify-content-md-between align-items-center p-0 pt-4 ${
              currentLocalization === "ar" && "flex-row-reverse"
            }`}
          >
            {selected && (
              <Col
                md={12}
                xs={11}
                className="p-md-0 text-start me-3"
                style={{ width: "97.5%" }}
              >
                <div
                  className={`topup-sim d-flex align-items-center justify-content-between ${
                    currentLocalization === "ar" && "flex-row-reverse pe-3"
                  } ps-3`}
                >
                  <div>
                    {currentLocalization === "ar" ? (
                      <>
                        <span className="me-2 sim-number pt-1">
                          <span>&nbsp;{t("topup.simNumber")}</span>
                          <span className="ms-2" style={{ fontWeight: "600" }}>
                            {selected?.sims[0]?.iccid}
                          </span>
                        </span>
                        <img
                          src={
                            selected?.vendorType === "esimgo"
                              ? esimgoSvg
                              : selected?.vendorType === "esimVault"
                              ? esimVaultSvg
                              : airaloSvg
                          }
                          alt="offer-icon"
                          width={50}
                        />
                      </>
                    ) : (
                      <>
                        <img
                          src={
                            selected?.vendorType === "esimgo"
                              ? esimgoSvg
                              : selected?.vendorType === "esimVault"
                              ? esimVaultSvg
                              : airaloSvg
                          }
                          alt="offer-icon"
                          width={50}
                        />
                        <span className="ms-2 sim-number pt-1">
                          <span style={{ fontWeight: "600" }}>
                            {selected?.sims[0]?.iccid}
                          </span>
                          <span>&nbsp;{t("topup.simNumber")}</span>
                        </span>
                      </>
                    )}
                  </div>
                  <div className="pe-3">
                    {multipleRecord.length > 1 && (
                      <button
                        onClick={() => {
                          setModalShow(true);
                          setSelectValueShow([selected]);
                        }}
                        className="change-sim-number"
                      >
                        {t("topup.changeNumber")}
                      </button>
                    )}
                  </div>
                </div>
              </Col>
            )}
          </Row>
          <Row className="p-0 pt-3 mb-2">
            <Col
              xs={12}
              className={`d-flex flex-row justify-content-md-between align-items-center p-md-0 ${
                currentLocalization === "ar" && "flex-row-reverse"
              }`}
            >
              <span
                className={`show-product-p ${
                  currentLocalization === "ar" && "me-3"
                }`}
              >
                {t("countryFilter.part4")} {!loading && simRecordLength}
                &nbsp;{t("countryFilter.part")}
              </span>
              <div
                className={`d-flex ${
                  currentLocalization === "ar"
                    ? "select-box-padding-right"
                    : "select-box-padding"
                }`}
              >
                {filterDetails?.length > 0 && (
                  <CurrencyNameSelect
                    onCurrencyChange={handleCurrencyChange}
                    selectedCurrency={selectedCurrency}
                    setSelectedCurrency={setSelectedCurrency}
                  />
                )}

                <div className="ms-3">
                  <SortSelect setPriceSorting={setPriceSorting} />
                </div>
              </div>
            </Col>
          </Row>
          <Row
            className={`d-flex flex-row justify-content-center  ${
              currentLocalization === "ar" && "flex-row-reverse"
            }`}
          >
            <Col
              lg={3}
              md={3}
              xs={10}
              className={`text-start ps-0 pe-3  mb-4 mt-4 ${
                currentLocalization === "ar" && "text-end"
              }`}
            >
              <div className="filter-box p-3">
                <div
                  className={`d-flex align-items-center justify-content-between ${
                    currentLocalization === "ar" && "flex-row-reverse"
                  }`}
                >
                  <h5 className="filters-h5">{t("countryFilter.part5")}</h5>
                  <p className="clear-filter" onClick={() => handleAllFilter()}>
                    {t("countryFilter.part29")}
                  </p>
                </div>
                <Row className="d-flex justify-content-center mt-3"></Row>
                <div className="mt-3">
                  <span className="package-type-span">
                    {t("countryFilter.part10")}
                  </span>
                  <div
                    className={`mt-2 ${
                      currentLocalization === "ar" &&
                      "d-flex flex-row-reverse flex-wrap"
                    }`}
                  >
                    <button
                      className={
                        !selectData ? "filter-button-active" : "filter-button"
                      }
                      onClick={() => handleDataFilter("")}
                      disabled={loading}
                    >
                      {t("countryFilter.part7")}
                    </button>
                    <button
                      className={
                        selectData === 3
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handleDataFilter(3)}
                      disabled={loading}
                    >
                      {t("countryFilter.part11")}
                    </button>
                    <button
                      className={
                        selectData === 5
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handleDataFilter(5)}
                      disabled={loading}
                    >
                      {t("countryFilter.part12")}
                    </button>
                    <button
                      className={
                        selectData === 8
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handleDataFilter(8)}
                      disabled={loading}
                    >
                      {t("countryFilter.part13")}
                    </button>
                    <button
                      className={
                        selectData === 12
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handleDataFilter(12)}
                      disabled={loading}
                    >
                      {t("countryFilter.part14")}
                    </button>
                    <button
                      className={
                        selectData === 20
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handleDataFilter(20)}
                      disabled={loading}
                    >
                      {t("countryFilter.part15")}
                    </button>
                    <button
                      className={
                        selectData === 50
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handleDataFilter(50)}
                      disabled={loading}
                    >
                      {t("countryFilter.part16")}
                    </button>
                  </div>
                </div>
                <Row className="d-flex justify-content-center mt-3">
                  <Col
                    md={11}
                    xs={11}
                    style={{ borderTop: "1.5px solid #D9D9D9" }}
                  ></Col>
                </Row>
                <div className="mt-3">
                  <span className="package-type-span">
                    {t("countryFilter.part17")}
                  </span>
                  <div
                    className={`mt-2 ${
                      currentLocalization === "ar" &&
                      "d-flex flex-row-reverse flex-wrap"
                    }`}
                  >
                    <button
                      className={
                        selectDay === ""
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handleDayFilter("")}
                      disabled={loading}
                    >
                      {t("countryFilter.part7")}
                    </button>
                    <button
                      className={
                        selectDay === 7
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handleDayFilter(7)}
                      disabled={loading}
                    >
                      {t("countryFilter.part18")}
                    </button>
                    <button
                      className={
                        selectDay === 14
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handleDayFilter(14)}
                      disabled={loading}
                    >
                      {t("countryFilter.part19")}
                    </button>
                    <button
                      className={
                        selectDay === 30
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handleDayFilter(30)}
                      disabled={loading}
                    >
                      {t("countryFilter.part20")}
                    </button>
                    <button
                      className={
                        selectDay === 60
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handleDayFilter(60)}
                      disabled={loading}
                    >
                      {t("countryFilter.part21")}
                    </button>
                    <button
                      className={
                        selectDay === 90
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handleDayFilter(90)}
                      disabled={loading}
                    >
                      {t("countryFilter.part22")}
                    </button>
                  </div>
                </div>
                <Row className="d-flex justify-content-center mt-3">
                  <Col
                    md={11}
                    xs={11}
                    style={{ borderTop: "1.5px solid #D9D9D9" }}
                  ></Col>
                </Row>
                <div className="mt-3">
                  <span className="package-type-span">
                    {t("countryFilter.part23")}
                  </span>
                  <div
                    className={`mt-2 ${
                      currentLocalization === "ar" &&
                      "d-flex flex-row-reverse flex-wrap"
                    }`}
                  >
                    <button
                      className={
                        selectPrice === ""
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handlePriceFilter("")}
                      disabled={loading}
                    >
                      {t("countryFilter.part7")}
                    </button>
                    <button
                      className={
                        selectPrice === 10
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handlePriceFilter(10)}
                      disabled={loading}
                    >
                      {t("countryFilter.part24")}
                    </button>
                    <button
                      className={
                        selectPrice === 20
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handlePriceFilter(20)}
                      disabled={loading}
                    >
                      {t("countryFilter.part25")}
                    </button>
                    <button
                      className={
                        selectPrice === 30
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handlePriceFilter(30)}
                      disabled={loading}
                    >
                      {t("countryFilter.part26")}
                    </button>
                    <button
                      className={
                        selectPrice === 40
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handlePriceFilter(40)}
                      disabled={loading}
                    >
                      {t("countryFilter.part27")}
                    </button>
                    <button
                      className={
                        selectPrice === 50
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handlePriceFilter(50)}
                      disabled={loading}
                    >
                      {t("countryFilter.part28")}
                    </button>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={9} xs={11} className="">
              {loading ? (
                <div
                  className="w-100 d-flex justify-content-center align-items-center"
                  style={{ paddingTop: "150px", paddingBottom: "150px" }}
                >
                  <Spinner animation="border" variant="dark" />
                </div>
              ) : (
                <Row className="mt-3">
                  <Col
                    xs={12}
                    className={`d-flex flex-wrap p-0 ${
                      currentLocalization === "ar"
                        ? "justify-content-start"
                        : "justify-content-start"
                    }`}
                  >
                    {filterDetails?.length > 0 ? (
                      <>
                        {filterDetails?.map((item, index) => {
                          return (
                            <TopupCard
                              item={item}
                              key={index}
                              index={index}
                              offers={eSimDetails}
                              exchangeRate={exchangeRate}
                              currencySymbol={currencySymbol}
                              selected={selected}
                              getCountryss={getCountryss}
                              user={user}
                            />
                          );
                        })}
                      </>
                    ) : (
                      <div className="w-100 pt-5 pb-5">
                        <span>{t("home.part28")}</span>
                      </div>
                    )}
                  </Col>
                </Row>
              )}
            </Col>
          </Row>

          <Row className="d-flex mt-5 justify-content-center">
            <Col className="m-0 p-0" md={12} xs={11}>
              <Footer />
            </Col>
          </Row>
        </Container>
      </section>

      <TopUpModal
        selected={selected}
        handleSelect={handleSelect}
        setModalShow={setModalShow}
        modalShow={modalShow}
        simRecord={multipleRecord}
        onClick={selectPackage}
        selectLoading={selectLoading}
        error={error}
        selectValueShow={selectValueShow}
      />
    </div>
  );
};

export default TopUp;
