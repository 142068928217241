import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthUserContext } from "../context/context";

const ApplyFilterButton = ({ onApply }) => {
  const { t } = useTranslation();
  const { currentLocalization } = useContext(AuthUserContext);
  return (
    <div className="apply-filter">
    <div style={{ position: 'relative', width: 'max-content' }}>
    <button
      onClick={onApply}
      style={{
        padding: '10px 20px',
        backgroundColor: '#004940',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '14px',
        transition: 'background-color 0.3s ease',
      }}
      onMouseEnter={(e) => e.target.style.backgroundColor = '#003d38'}
      onMouseLeave={(e) => e.target.style.backgroundColor = '#004940'} 
    >
      {t("Apply Filter")}
    </button>
    </div>
    </div>
  );
};

export default ApplyFilterButton;
