import React, { useState } from "react";
import Sidebar from "../../component/Sidebar/SideBar";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import copy from "../../assets/images/copy.svg";
const Script = () => {
  const [isCopied, setIsCopied] = useState(false);
  const [tagCopied, setTagCopied] = useState(false);
  const [allTextCopied, setAllTextCopied] = useState(false);
  const script = `<script src="${process.env.REACT_APP_URL}/appendContent.js"></script>`;
  const tag = `<div id="customDiv"></div>`;
  const fullText = `
    Step 1: Include the Script
    - Copy the script provided below.
    - Include the following script tag in your HTML file, either within the <head> or <body> section.

    ${script}

    Step 2: Create a Div with a Specific ID
    - Add a <div> element in your HTML file at the location where you want the content (image and heading) to appear.
    - Ensure that you assign the ID 'customDiv' to this div.

    ${tag}

    This script will automatically populate the div with the ID 'customDiv' with the desired content.
    `;
  const handleCopy = () => {
    setTagCopied(false)
    setAllTextCopied(false)
    navigator.clipboard.writeText(script).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    });
  };
  const handleTagCopy = () => {
    setIsCopied(false)
    setAllTextCopied(false)
    navigator.clipboard.writeText(tag).then(() => {
      setTagCopied(true);
      setTimeout(() => setTagCopied(false), 2000);
    });
  };
  const handleFullCopy = () => {
    setIsCopied(false)
    setTagCopied(false)
    navigator.clipboard.writeText(fullText).then(() => {
      setAllTextCopied(true);
      setTimeout(() => setAllTextCopied(false), 2000);
    });
  };
  return (
    <Sidebar>
      <Container>
        <Row>
          <Col md={12} className="text-start package-h2 p-0">
            <h2>Script</h2>
          </Col>
          <Col md={12} className="script-box text-start p-4 mt-4">
            <h3 className="global-tag-h3 mt-2">Global Site Tag</h3>
            <p className="global-tag-p mt-3">
              This section provides a global site tag script that can be
              integrated into other websites
            </p>
            <div className="position-relative">
              <div className="text-end position-absolute top-0 end-0">
                <div
                  className="copy-container"
                  style={{ position: "relative" }}
                >
                  <img
                    src={copy}
                    alt="copy all"
                    onClick={handleFullCopy}
                    style={{ cursor: "pointer" }}
                  />
                  {allTextCopied && (
                    <div className="custom-tooltip">
                      Script, tag & text copied
                    </div>
                  )}
                </div>
              </div>
              <div>
                <h6 className="script-text mt-4">Step 1: Include the Script</h6>
                <ul className="ps-4 script-text">
                  <li>Copy the script provided below.</li>
                  <li>
                    Include the following script tag in your HTML file, either
                    within the &lt;head&gt; or &lt;body&gt; section.
                  </li>
                </ul>
              </div>
              <div className="script-box-down p-4 pt-3 pb-3 mt-3 mb-3">
                <Row className="flex flex-md-row flex-column-reverse align-items-center">
                  <Col md={7} xs={12} className="script-text">
                    {script}
                  </Col>
                  <Col md={5} xs={12} className="text-end pe-lg-3">
                    <div
                      className="copy-container"
                      style={{ position: "relative" }}
                    >
                      <img
                        src={copy}
                        alt="copy"
                        onClick={handleCopy}
                        style={{ cursor: "pointer" }}
                      />
                      {isCopied && (
                        <div className="custom-tooltip">Script copied</div>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
              <div>
                <h6 className="script-text mt-4">
                  Step 2: Create a Div with a Specific ID
                </h6>
                <ul className="ps-4 script-text">
                  <li>
                    Add a &lt;div&gt; element in your HTML file at the location
                    where you want the content (image and heading) to appear.
                  </li>
                  <li>
                    Ensure that you assign the ID <code>customDiv</code> to this
                    div.
                  </li>
                </ul>
              </div>
              <div className="script-box-down p-4 pt-3 pb-3 mt-3 mb-3">
                <Row className="flex flex-md-row flex-column-reverse align-items-center">
                  <Col md={7} xs={12} className="script-text">
                    {tag}
                  </Col>
                  <Col md={5} xs={12} className="text-end pe-lg-3">
                    <div
                      className="copy-container"
                      style={{ position: "relative" }}
                    >
                      <img
                        src={copy}
                        alt="copy"
                        onClick={handleTagCopy}
                        style={{ cursor: "pointer" }}
                      />
                      {tagCopied && (
                        <div className="custom-tooltip">Script copied</div>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
              <div>
                <p className="script-text mt-4">
                  This script will automatically populate the div with the ID<code> customDiv</code> with the desired content.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Sidebar>
  );
};

export default Script;
