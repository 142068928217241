import React, { useRef, useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthUserContext } from "../context/context";

const SortSelect = ({ setPriceSorting,setSelectData,setSelectDays,setSelectPrice }) => {
  const { i18n, t } = useTranslation();
  const { currentLocalization } = useContext(AuthUserContext);
  const getDirection = () => {
    return i18n.language === "ar" ? "rtl" : "ltr";
  };
  const options = [
    { value: "all", text: "countryFilter.part33" },
    { value: "price", text: "countryFilter.part34" },
    { value: "data", text: "countryFilter.part35" },
    { value: "validity", text: "countryFilter.part36" },
  ];
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const selectRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleOptionClick = (option) => {
    setPriceSorting(option.value)
    setSelectedOption(option);
    setIsOpen(false);
    setSelectData("")
    setSelectDays("")
    setSelectPrice("")
  };

  return (
    <div style={{ direction: getDirection() }}>
      <div className="select textField-language" ref={selectRef}>
        <div
          className={`select-styled textField-language-sorting ${isOpen ? "active" : ""}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          {selectedOption.value === "price" ? t("table.Price") : selectedOption.value === "data" ? t("table.Data") : selectedOption.value === "validity" ? t("home.part18") : t(selectedOption.text)}
        </div>
        <ul className={`${currentLocalization === "ar" ? "select-options-right" : "select-options"} ${isOpen ? "show" : ""}`}>
          {options.map((option) => (
            <li
              key={option.value}
              rel={option.value}
              className={
                selectedOption.value === option.value ? "is-selected" : ""
              }
              onClick={() => handleOptionClick(option)}
            >
              {t(option.text)}

            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SortSelect;
