import React, { useState, useContext } from "react";
import Sidebar from "../../../component/Sidebar/SideBar";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Input from "../../../component/Input";
import upload from "../../../assets/images/upload-image.svg";
import addPartner from "../../../services/partner/addPartner";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AuthUserContext } from "../../../context/context";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";
import PatnerAddModal from "../../../component/patnerAddModal";
import checkPartnerExists from "../../../services/partner/checkPartnerExists";
const AddPartner = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [cropper, setCropper] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [imageName, setImageName] = useState("");
  const [tempImageName, setTempImageName] = useState("");
  const { t } = useTranslation();
  const { currentLocalization, setToastId } = useContext(AuthUserContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    companyName: "",
    domain: "",
    email: "",
    commissionPercentage: "",
    commissionAmount: "",
    image: ""
  });
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    const newErrors = { ...errors };

    if ((name === "commissionPercentage" || name === "commissionAmount") && value < 0) {
      newErrors.commissionError = "Value cannot be negative!";
      newErrors.commissionPercentage = "Value cannot be negative!";
      return;
    }
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "commissionPercentage" || name === "commissionAmount") {
      if (value && value <= 0) {
        newErrors.commissionError = "Value cannot be negative!";
        newErrors.commissionPercentage = "Value cannot be negative!";
      }
    }
    if (name === "email") {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        newErrors.email = "Valid email is required";
      } else {
        delete newErrors.email;
      }
    }
    if (name === "domain") {
      if (!/^[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/.test(value)) {
        newErrors.domain = "Valid domain is required (e.g., gleesim.com)";
      } else {
        delete newErrors.domain;
      }
    }
    setErrors(newErrors);
  };
  const validate = () => {
    const newErrors = {};
    if (!formData.companyName)
      newErrors.companyName = "Company name is required";
    if (!formData.domain) {
      newErrors.domain = "Domain is required";
    } else if (!/^[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/.test(formData.domain)) {
      newErrors.domain = "Valid domain is required (e.g., gleesim.com)";
    }
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
        newErrors.email = "Valid email is required";
      }
    }
    if (!formData.commissionPercentage && !formData.commissionAmount) {
      newErrors.commissionError =
        "Both commission percentage and amount are required";
    } else {
      if (!formData.commissionPercentage) {
        newErrors.commissionPercentage = "Commission percentage is required";
      }
      if (!formData.commissionAmount) {
        newErrors.commissionAmount = "Commission amount is required";
      }
    }
    if (formData.commissionPercentage < 0) {
      newErrors.commissionPercentage = "Commission percentage cannot be negative!";
    }
    if (formData.commissionAmount < 0) {
      newErrors.commissionAmount = "Commission amount cannot be negative!";
    }
    if (!formData.image) {
      newErrors.image = "Image is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const truncateName = (name, maxLength = 20) => {
    if (name.length > maxLength) {
      const start = name.substring(0, maxLength / 2);
      const end = name.substring(name.length - maxLength / 2);
      return `${start}...${end}`;
    }
    return name;
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const validImageTypes = ["image/jpeg", "image/png", "image/gif", "image/webp"];
      if (!validImageTypes.includes(file.type)) {
        setErrors((prev) => ({
          ...prev,
          image: "Only images (jpg, png, gif, webp) are allowed",
        }));
        return;
      }
      setTempImageName(file.name);

      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      setShowCropper(true);
      e.target.value = null;
    }
  };
  const handleCrop = () => {
    if (cropper) {
      const croppedCanvas = cropper.getCroppedCanvas();
      croppedCanvas.toBlob((blob) => {
        setCroppedImage(blob);
        setFormData({ ...formData, image: blob });
        setImageName(tempImageName);
        setShowCropper(false);
      }, "image/jpeg");
    }
  };
  const truncatedImageName = truncateName(imageName, 20);
  const handleSubmit = async () => {
    try {
      if (validate()) {
        setLoading(true)

        const { email, domain } = formData;
        const existsResponse = await checkPartnerExists({ email, domain });
        if (existsResponse.exists) {
          const id = toast.error(
            "A partner with this email or domain already exists!",
            {
              autoClose: false,
              closeOnClick: false,
              draggable: false,
              pauseOnHover: true,
            }
          );
          setToastId(id);
          setLoading(false);
          return;
        }


        const formSubmissionData = new FormData();
        formSubmissionData.append("companyName", formData.companyName);
        formSubmissionData.append("email", formData.email);
        formSubmissionData.append("domain", formData.domain);
        formSubmissionData.append("commissionPercentage", formData.commissionPercentage);
        formSubmissionData.append("commissionAmount", formData.commissionAmount);
        formSubmissionData.append("image", formData.image);
        const response = await addPartner(formSubmissionData)
        const id = toast.success(response.message, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
        navigate("/partner-listing")
      }
    } catch (err) {
      console.log("e", err);
      if (err.response?.status == 401) {
        const id = toast.error(t("toastsMessage.part4"), {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
        window.scroll(0, 0);
        localStorage.clear();
        navigate("/log-in");
      } else {
        console.log("err", err);
        const id = toast.error(err.message, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
      }
    } finally {
      setLoading(false)
    }
  };

  return (
    <>
      <Sidebar>
        <Container>
          <Row>
            <Col md={12} className="text-start package-h2 p-0">
              <h2>{t("partnerListing.addPartner")}</h2>
            </Col>
            <Col
              md={12}
              className="script-box text-start mt-4 pb-5"
              style={{ padding: "30px" }}
            >
              <Row>
                <Col xl={6} md={12} xs={12} className="">
                  <Input
                    labelClassName="label"
                    labelText={t("partnerListing.companyName")}
                    type="text"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleChange}
                    placeholder="Enter Company Name"
                    className="login-input-left"
                  />
                  {errors.companyName && !formData.companyName && (
                    <span className="text-danger">{errors.companyName}</span>
                  )}
                </Col>
                <Col xl={6} md={12} xs={12} className="mt-xl-0 mt-3">
                  <label className="label">
                    {t("partnerListing.companyLogo")}{" "}
                    <span className="text-danger-asterisk">*</span>
                  </label>
                  <div
                    className={`file-input-wrapper ${croppedImage ? "image-selected" : ""}`}
                  >
                    {croppedImage ? (
                      <div>
                        <img
                          src={URL.createObjectURL(croppedImage)}
                          alt="Cropped"
                          className="image-preview"
                          onClick={() => setShowCropper(true)}
                        />
                        <span className="ps-3 image-name-text">{truncatedImageName}</span>
                      </div>
                    ) : (
                      <div>Upload Logo</div>
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      className="file-input"
                    />
                    <img src={upload} alt="upload" />
                  </div>
                  {showCropper && (
                    <Modal show={showCropper} onHide={() => setShowCropper(false)} centered size="lg" className="cropper-modal">
                      <Modal.Header closeButton>
                        <Modal.Title>Crop Image</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Cropper
                          src={selectedImage}
                          style={{ width: "100%", maxHeight: "300px" }}
                          aspectRatio={null}
                          guides={true}
                          viewMode={1}
                          scalable
                          cropBoxResizable
                          onInitialized={(instance) => setCropper(instance)}
                        />
                      </Modal.Body>
                      <Modal.Footer>
                        <Col xl={2} lg={4} md={4} xs={12} className="mt-2 pe-md-0">
                          <button
                            onClick={() => {
                              setShowCropper(false)
                            }} className="cancel-partner-button"
                          >
                            Cancel
                          </button>
                        </Col>
                        <Col xl={2} lg={4} md={4} xs={12} className="mt-2 pe-md-0">
                          <button onClick={handleCrop} className="add-partner-button">
                            Crop
                          </button>
                        </Col>
                      </Modal.Footer>
                    </Modal>
                  )}
                  {errors.image && !formData.image && (
                    <span className="text-danger">{errors.image}</span>
                  )}
                </Col>
                <Col xl={6} md={12} xs={12} className="mt-3">
                  <Input
                    labelClassName="label"
                    labelText={t("partnerListing.companyEmail")}
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Enter Company Email"
                    className="login-input-left"
                  />
                  {errors.email && (
                    <span className="text-danger">{errors.email}</span>
                  )}
                </Col>
                <Col xl={6} md={12} xs={12} className="mt-3">
                  <label className="label">
                    {t("partnerListing.commissionPercentage")}
                    <span className="text-danger-asterisk">*</span>
                  </label>
                  <div className="login-input-left d-flex align-items-center commission-box  flex-wrap pt-1 pb-1">
                    <input
                      type="number"
                      name="commissionPercentage"
                      value={formData.commissionPercentage}
                      onChange={handleChange}
                      className="percentage-input mt-lg-0 mt-2"
                      placeholder="Percentage"
                      min="0"
                    />
                    &nbsp;&nbsp;at&nbsp;&nbsp;
                    <input
                      type="number"
                      name="commissionAmount"
                      value={formData.commissionAmount}
                      onChange={handleChange}
                      className="percentage-input mt-lg-0 mt-2"
                      placeholder="Amount"
                      min="0"
                    />
                    &nbsp;&nbsp;{t("partnerListing.minimumsale")}
                    </div>
                    <div>
                      <small className="text-muted d-block ps-2 mt-2">
                        {t("partnerListing.commissiontext")}
                      </small>
                    </div>

                  {errors.commissionError && !formData.commissionPercentage && !formData.commissionAmount && (
                    <span className="text-danger">{errors.commissionError}</span>
                  )}
                  {!errors.commissionError && errors.commissionPercentage && !formData.commissionPercentage && (
                    <span className="text-danger">
                      {errors.commissionPercentage}
                    </span>
                  )}
                  {!errors.commissionError && errors.commissionAmount && !formData.commissionAmount && (
                    <span className="text-danger">{errors.commissionAmount}</span>
                  )}
                </Col>
                <Col xl={6} md={12} xs={12} className="mt-3">
                  <Input
                    labelClassName="label"
                    labelText={t("partnerListing.companyDomain")}
                    type="text"
                    name="domain"
                    value={formData.domain}
                    onChange={handleChange}
                    placeholder="Enter Company Domain"
                    className="login-input-left"
                  />
                  {errors.domain && (
                    <span className="text-danger">{errors.domain}</span>
                  )}
                </Col>
                <Col md={6} xs={12}></Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-4  d-flex justify-content-end flex-md-row flex-column-reverse">
            <Col xl={2} lg={4} md={4} xs={12} className="mt-2 ps-lg-0 p-0">
              <button className="cancel-partner-button" disabled={loading} onClick={() => navigate("/partner-listing")}>Cancel</button>
            </Col>
            <Col xl={2} lg={4} md={4} xs={12} className="mt-2 pe-lg-0 ps-md-2 p-0">
              <button className="add-partner-button" onClick={handleSubmit} disabled={loading}>
                {loading ? "Loading..." : "Add"}
              </button>
            </Col>
          </Row>
        </Container>
      </Sidebar>
    </>

  );
};

export default AddPartner;
