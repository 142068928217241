import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../component/Sidebar/SideBar";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { useTranslation } from "react-i18next";
import { AuthUserContext } from "../../context/context";
import getPackagesDetails from "../../services/packages/getPackages";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DataTables from "../../component/DataTable";
import { useLocation } from "react-router-dom";
import { SIM_PURCHASE, USER_DETAILS } from "../../constants/localstorageKeys";
import { Badge } from "react-bootstrap";
import LabelModal from "../../component/LabelModal";
import addSimLabel from "../../services/packages/addLabel";
import esimVaultSvg from "../../assets/images/esimVault.svg";
import airaloSvg from "../../assets/images/airalo.svg";
import esimgoSvg from "../../assets/images/esimgo.svg";
const DataUsage = () => {
  const { t } = useTranslation();
  const { currentLocalization, setToastId } = useContext(AuthUserContext);
  const navigate = useNavigate();
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortedUsers, setSortedUsers] = useState([]);
  const [user, setUser] = useState();
  const [modalShow, setModalShow] = useState(false);
  const location = useLocation();
  const [error, setError] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [addLabel, setAddLabel] = useState("");
  const [simForLabel, setSimForLabel] = useState(null);
  const [showDiscountColumn, setShowDiscountColumn] = useState(true);
  const getPackages = async () => {
    try {
      setLoading(true);
      const response = await getPackagesDetails();
      setPackages(response.data);
      const hasNullParentId = response.data.some(row => row?.userId?.parent_id === null);
      setShowDiscountColumn(!hasNullParentId);

    } catch (err) {
      if (err.response?.status == 401) {
        const id = toast.error(t("toastsMessage.part4"), {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
        window.scroll(0, 0);
        localStorage.clear();
        navigate("/log-in");
      } else {
        const id = toast.error(err.message, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
      }
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      name: <span className="dataTable-text">{t("table.vendor")}</span>,
      key: "vendorType",
      cell: (row) => (
        <div className="text-start column-text d-flex align-items-center">
          <div className="" style={{ width: "55px" }}>
            <img
              src={
                row?.vendorType == "esimgo"
                  ? esimgoSvg
                  : row?.vendorType === "esimVault"
                    ? esimVaultSvg
                    : airaloSvg
              }
              alt="offer-icon"
              width={40}
            />
          </div>
          <span className="ms-2">{row?.vendorType}</span>
        </div>
      ),
      sortable: true,
      width: "180px",
    },
    {
      name: <span className="dataTable-text">{t("table.Status")}</span>,
      selector: (row) => (
        <h5 className="" style={{ marginTop: "8px" }}>
          <Badge
            bg={
              new Date() - new Date(row?.created_at) <=
                row.validity * 24 * 60 * 60 * 1000
                ? "success"
                : "danger"
            }
          >
            {new Date() - new Date(row?.created_at) <=
              row?.validity * 24 * 60 * 60 * 1000
              ? "Active"
              : "Expire"}
          </Badge>
        </h5>
      ),
      width: "120px",
    },
    {
      name: <span className="dataTable-text">{t("table.simName")}</span>,
      key: "sims.0.iccid",
      selector: (row) => (
        <span className="text-start column-text">
          {row?.label ? row?.label : "-"}
        </span>
      ),
      sortable: true,
      width: currentLocalization == "in" || currentLocalization == "fr" ? "160px": "140px",
    },
    {
      name: <span className="dataTable-text">{t("table.simid")}</span>,
      key: "sims.0.iccid",
      selector: (row) => (
        <span className="text-start column-text">{row?.sims[0]?.iccid}</span>
      ),
      sortable: true,
      width: "240px",
    },
    {
      name: <span className="dataTable-text">{t("table.QrCodeUrl")}</span>,
      selector: (row) => (
        <a
          href={row?.sims?.[0].qrcode_url}
          target="_blank"
          className="column-text"
          style={{
            textDecoration: "initial",
            color: "#054940",
            fontSize: "16px",
            fontWeight: "600",
            textDecoration: "",
          }}
        >
          QR Code
        </a>
      ),
      width: currentLocalization == "ar" ? "170px": "150px",
    },
    {
      name: <span className="dataTable-text"> {t("table.expiration")}</span>,
      selector: (row) => (
        <span className="text-start column-text">
          {new Date(
            new Date(row?.created_at).getTime() +
            row?.validity * 24 * 60 * 60 * 1000
          ).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "long",
            year: "numeric",
          })}
        </span>
      ),
      width: currentLocalization == "in"? "220px": currentLocalization == "fr" ? "200px" : "160px",
    },
    {
      name: <span className="dataTable-text">{t("table.validDays")}</span>,
      key: "validity",
      selector: (row) => (
        <span className="text-start column-text">
          {row?.validity} {t("home.part21")}
        </span>
      ),
      sortable: true,
      width: currentLocalization == "in" || currentLocalization == "fr" ? "185px": "150px",
    },
    {
      name: <span className="dataTable-text">{t("table.remainingData")}</span>,
      selector: (row) => (
        <span className="text-start column-text">
          {row && row.dataUseage && row.dataUseage.remaining
            ? `${(row.dataUseage.remaining / 1024).toFixed(2)} ${t(
              "home.part22"
            )}`
            : `${row?.data.replace("GB", "").trim()} ${t("home.part22")}`}
        </span>
      ),
      width: currentLocalization == "in" || currentLocalization == "fr" ? "210px": "185px",
    },
    {
      name: <span className="dataTable-text">{t("table.Data")}</span>,
      key: "data",
      selector: (row) => (
        <span className="text-start column-text">
          {row?.data.replace("GB", "").trim()} {t("home.part22")}
        </span>
      ),
      sortable: true,
      width: "90px",
    },
    {
      name: <span className="dataTable-text">{t("table.Country")}</span>,
      key: "country",
      selector: (row) => (
        <span className="text-start column-text">{row?.country}</span>
      ),
      sortable: true,
      width: "240px",
    },
    {
      name: <span className="dataTable-text">{t("table.packageName")}</span>,
      key: "package",
      selector: (row) => row.package,
      cell: (row) => (
        <span className="text-start column-text">
          {row.package && row.package?.length > 35
            ? `${row.package.slice(0, 35)}...`
            : row.package}
        </span>
      ),
      sortable: true,
      width: "220px",
    },
     {
      name: <span className="dataTable-text">{!showDiscountColumn ? t("table.orignalPrice") : t("table.Price")}</span>,
      key: "price",
      selector: (row) => (
        <span className="text-start column-text">
          $ {row?.price.toFixed(2)}
        </span>
      ),
      sortable: true,
      width: "180px",
    }, {
      name: <span className="dataTable-text">{t("table.Discount")}</span>,
      key: "price",
      selector: (row) => (
        <span className="text-start column-text">
          {row.discountedPer ? row.discountedPer + "%" : '-'}       </span>
      ),
      sortable: true,
      width: "120px",
      omit: showDiscountColumn
    },
    {
      name: <span className="dataTable-text">{t("table.Price")}</span>,
      key: "price",
      selector: (row) => (
        <span className="text-start column-text">
          $ {(row?.discountedPrice ? parseFloat(row?.discountedPrice).toFixed(2) : parseFloat(row?.price).toFixed(2))}
        </span>
      ),
      sortable: true,
      width: "120px",
      omit: showDiscountColumn
    },
    {
      name: <span className="dataTable-text">{t("table.action")}</span>,
      key: "price",
      selector: (row) => (
        <div className="">
          <button
            className="text-start btn btn-add-label"
            onClick={() => handleAddLabel(row)}
          >
            {t("detailsPage.part16")}
          </button>
        </div>
      ),
      sortable: true,
      width: currentLocalization == "in" || currentLocalization == "fr" ? "210px": "145px",
    },
    
  ];
  const checkSimPurchage = async () => {
    try {
      const response = await getPackagesDetails();
      const hasTopUp = response?.data?.length > 0 ? true : false;
      localStorage.setItem(SIM_PURCHASE, hasTopUp);
    } catch (e) {
      console.log("e", e);
    }
  };
  const handleSort = async (column, direction) => {
    const nestedSort = (obj, path) => {
      const value = path
        .split(".")
        .reduce(
          (value, key) =>
            value && value[key] !== undefined ? value[key] : null,
          obj
        );
      if (path === "data") {
        return parseFloat(value.replace("GB", "").trim());
      }

      return value;
    };

    const sortedData = [...packages].sort((a, b) => {
      const aValue = nestedSort(a, column.key);
      const bValue = nestedSort(b, column.key);

      if (aValue < bValue) {
        return direction === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });

    setSortedUsers(sortedData);
  };
  const handleAddLabel = (row) => {
    setSimForLabel(row);
    setModalShow(true);
  };
  const handleSubmit = async () => {
    try {
      if (!addLabel) {
        setError(true);
        return;
      }
      setAddLoading(true);
      const response = await addSimLabel({
        iccid: simForLabel._id,
        label: addLabel,
      });
      if (response) {
        getPackages();
        const id = toast.success(response.message, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
        setSimForLabel(null);
        setAddLabel("");
        setModalShow(false);
      }
    } catch (e) {
      console.log("e", e);
    } finally {
      setAddLoading(false);
    }
  };
  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
    if (userDetails) {
      setUser(userDetails);
    }
  }, []);
  useEffect(() => {
    getPackages();
  }, [currentLocalization]);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    localStorage.removeItem("OUTSIDER_USER");
    if (params.get("payment_success")) {
      const id = toast.success(t("toastsMessage.part1"), {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        pauseOnHover: true
      });
      setToastId(id);
      params.delete("payment_success");
      checkSimPurchage();
      navigate(location.pathname, { replace: true });
    }
  }, [location.search, location.pathname, navigate]);
  return (
    <Sidebar>
      <Container>
        <Row>
          <Col
            className={currentLocalization == "ar" ? "text-end" : "text-start"}
          >
            <h2 className="package-h2">{t("dataUsage.dashboard")}</h2>
            {currentLocalization == "ar" ? (
              <>
                <span className="username-span">
                  {user && user.name ? user.name : ""}
                </span>
                <span className="welcome-span">
                  ,{t("dataUsage.welcomeBack")}
                </span>
              </>
            ) : (
              <>
                <span className="welcome-span">
                  {t("dataUsage.welcomeBack")},
                </span>
                <span className="username-span ms-1">
                  {user && user.name ? user.name : ""}
                </span>
              </>
            )}
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <div className="mt-4">
              <DataTables
                columns={columns}
                data={sortedUsers.length ? sortedUsers : packages}
                loading={loading}
                handleSort={handleSort}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <LabelModal
        modalShow={modalShow}
        setModalShow={setModalShow}
        error={error}
        addLoading={addLoading}
        setError={setError}
        setAddLabel={setAddLabel}
        addLabel={addLabel}
        handleSubmit={handleSubmit}
        setSimForLabel={setSimForLabel}
      />
    </Sidebar>
  );
};

export default DataUsage;
