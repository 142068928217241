const API_URL = process.env.REACT_APP_API_URL;
const routes = {
  BASE: API_URL,
  ESIM_LIST: API_URL + "/api/v1/esims-list",
  BUNDLE_DETAILS: API_URL + "/api/v1/bundle-details",
  CHECKOUT_SESSION: API_URL + "/api/v1/create-checkout-session",
  CHECKOUT_TOPUP_SESSION: API_URL + "/api/v1/create-stripe-topUp-session",
  // Auth
  REGISTER: API_URL + "/api/v1/register",
  VERIFY_OTP: API_URL + "/api/v1/verify-otp",
  LOGIN: API_URL + "/api/v1/login",
  RESEND_OTP: API_URL + "/api/v1/resend-otp",
  FORGET_PASSWORD: API_URL + "/api/v1/forget",
  UPDATE_PASSWORD: API_URL + "/api/v1/password-change",
  GOOGLE_LOGIN: API_URL + "/api/v1/google-login",
  UPDATE_USER: API_URL + "/api/v1/updateUser",

  // User Routes
  PACKAGES_LIST: API_URL + "/api/v1/getUserPackages",
  USER_LISTING: API_URL + "/api/v1/getUserListing",
  USER_CARD_DATA: API_URL + "/api/v1/getUsersCardData",
  GET_ALL_SIM_PACKAGE: API_URL + "/api/v1/getPackages",
  UPDATE_PACKAGE: API_URL + "/api/v1/updatePackagesPrice",
  ADD_LABEL: API_URL + "/api/v1/addLabel",
  //Top-up
  GET_TOPUP_PACKAGE_LIST: API_URL + "/api/v1/get-topup-packages",
  GET_TOPUP_LIST: API_URL + "/api/v1/get-topups",

  //Transactions
  GET_TRANSACTIONS_LIST: API_URL + "/api/v1/getUserTransactions",

  // Content Management
  CREATE_SECTION: API_URL + "/api/v1/section",
  GET_SECTION: API_URL + "/api/v1/get-sections",
  UPDATE_SECTION: API_URL + "/api/v1/section",
  DELETE_SECTION: API_URL + "/api/v1/delete-section",
  CONTACT_US: API_URL + "/api/v1/contact",
  PUBLISH: API_URL + "/api/v1/publish",

  // Partner
  CREATE_PARTNER: API_URL + "/api/v1/create-partner",
  checkPartnerExists: API_URL + "/api/v1/checkPartnerExists",
  GET_ALL_PARTNER: API_URL + "/api/v1/partners",
  GET_PARTNER_BY_ID: API_URL + "/api/v1/partners",
  DELETE_PARTNER: API_URL + "/api/v1/partners",
  UPDATE_PARTNER: API_URL + "/api/v1/partners",
  SET_PARTNER_PASSWORD: API_URL + "/api/v1/set-paasword",
};
export default routes;
