import React, { useContext } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { FcGoogle } from "react-icons/fc";
import { useTranslation } from "react-i18next";
import { AuthUserContext } from "../context/context";

const GoogleLoginButton = ({ handleGoogleSuccess, handleFailure }) => {
  const { t } = useTranslation();
  const { currentLocalization} = useContext(AuthUserContext);
  const googleLogin = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      handleGoogleSuccess(credentialResponse);
    },
    onError: () => {
      handleFailure();
    },
  });
  
  return (
    <button
      onClick={() => googleLogin()}
      style={{
        backgroundColor: "transparent",
        color: "#054940",
        border: "1px solid #054940",
        borderRadius: "5px",
        width: "100%",
        paddingTop:"10px",
        paddingBottom:"10px",
        fontSize: "18px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        fontWeight: "500",
        gap: "6px",
        transition: "background-color 0.3s ease, color 0.3s ease",
        height: "56px",
        marginTop: "6px",
        fontFamily: "Poppins"
      }}
      className={`${currentLocalization === "ar" ? "flex-row-reverse" : "flex-row"}`}
    >
     <FcGoogle size={25}/>
      {t("Sign in with Google")}
    </button>
  );
};

export default GoogleLoginButton;
