import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../component/Sidebar/SideBar";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { useTranslation } from "react-i18next";
import { AuthUserContext } from "../../context/context";
import Form from "react-bootstrap/Form";
import Input from "../../component/Input";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import MultiSelect from "../../component/MultiSelect ";
import { currencySymbols } from "../../constants/currency";
import PasswordInput from "../../component/PasswordInput";
import { useNavigate } from "react-router-dom";
import { USER_DETAILS } from "../../constants/localstorageKeys";
import { LuCopy, LuCopyCheck } from "react-icons/lu";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import updateUser from "../../services/auth/profileUpdate";

const ProfileSetting = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [validated, setValidated] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({ countries: [], phone_no: "" });
  const [formData, setFormData] = useState({
    phoneNo: "",
    countries: [],
    password: "",
    confirmpassword: "",
  });
  const [selectedCountries, setSelectedCountries] = useState([]);
  const navigate = useNavigate();
  const [referralLink, setReferralLink] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const { t } = useTranslation();
  const { currentLocalization } = useContext(AuthUserContext);

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
    if (userDetails) {
      setUser(userDetails);
      setFormData({
        phoneNo: userDetails.phoneNo || "",
        countries: userDetails.countries || [],
        password: "",
        confirmpassword: "",
      });
      setSelectedCountries(userDetails.countries || []);
    }
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phoneNo") {
      let phoneNo = value.replace(/[^\d]/g, "");
      if (!phoneNo.startsWith("+")) {
        phoneNo = "+" + phoneNo;
      }
      if (phoneNo.length > 16) {
        phoneNo = phoneNo.slice(0, 16);
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: phoneNo,
      }));
      return;
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === "password" || name === "confirmpassword") {
      const isConfirmPasswordValid =
        formData.password && formData.confirmpassword === value;
      setPasswordValidation(!isConfirmPasswordValid);
    }
  };

  const handleCountryChange = (selectedOptions) => {
    const updatedCountries = [
      ...new Set([...selectedCountries, ...selectedOptions]),
    ];
    setSelectedCountries(updatedCountries);
    setFormData((prevFormData) => ({
      ...prevFormData,
      countries: updatedCountries,
    }));
  };
  const handleTogglePassword = (event) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  };
  const handleToggleConfirmPassword = (event) => {
    event.preventDefault();
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !isLoading) {
      event.preventDefault();
      handleSubmit(event);
    }
  };
  const handleCancel = (event) => {
    event.preventDefault();
    navigate("/data-usage");
    window.scroll(0, 0);
  };
  const handleCopy = () => {
    if (referralLink) {
      navigator.clipboard
        .writeText(referralLink)
        .then(() => {
          setIsCopied(true);
          setTimeout(() => setIsCopied(false), 1000);
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    } 
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidated(false);
    setPasswordValidation(false);

    const phoneDigits = formData.phoneNo.replace(/[^0-9]/g, "");
    if (phoneDigits.length < 8 || phoneDigits.length > 15) {
      setValidated(true);
      return;
    }

    if (selectedCountries.length === 0) {
      setValidated(true);
      return;
    }

    if (formData.password !== formData.confirmpassword) {
      setPasswordValidation(true);
      toast.error("Passwords do not match", {
        autoClose: 1500,
        closeOnClick: false,
        draggable: false,
        pauseOnHover: true,
      });
      return;
    }
    setIsLoading(true);

    try {
      const userId = user?.id;
      if (!userId) {
        throw new Error("User ID is undefined");
      }
      const updateData = {
        phone_no: formData.phoneNo,
        countries: selectedCountries,
      };
      if (formData.password) {
        updateData.password = formData.password;
      }

      const response = await updateUser(userId, updateData);

      const updatedUser = { ...user, ...response.data };
      setUser(updatedUser);
      localStorage.setItem(USER_DETAILS, JSON.stringify({ ...updatedUser, phoneNo: updatedUser.phone_no }));
      toast.success(response?.message, {
        autoClose: 1500,
        closeOnClick: false,
        draggable: false,
        pauseOnHover: true,
      });
    } catch (error) {
      toast.error(error?.response?.data?.message || "Error updating profile", {
        autoClose: 1500,
        closeOnClick: false,
        draggable: false,
        pauseOnHover: true,
      });
    } finally {
      setIsLoading(false);
      setFormData((prevFormData) => ({
        ...prevFormData,
        password: "",
        confirmpassword: "",
      }));
    }
  };
  useEffect(() => {
    if (user?.referralCode) {
      const baseUrl = window.location.origin;
      setReferralLink(`${baseUrl}?ref=${user?.referralCode}`);
    }
  }, [user?.referralCode]);
  return (
    <Sidebar>
      <Container>
        <Row>
          <Col>
            <div
              className={
                currentLocalization === "ar" ? "text-end" : "text-start"
              }
            >
              <h3 className="package-h2 mt-5">
                {t("dataUsage.profileSettings")}
              </h3>
            </div>
          </Col>
        </Row>

        <Form 
          method="post"
          onSubmit={handleSubmit}
          onKeyDown={handleKeyDown}
          className="mt-4"
        >
          <Row
            className={` ${
              currentLocalization === "ar"
                ? "d-flex flex-row-reverse text-end mt-4"
                : "d-flex flex-row text-start mt-4"
            }`}
          >
            <Col xs={12} md={5}>
              <Form.Group as={Col} md="12" className="d-flex flex-column mt-3">
                <Input
                  labelClassName="label"
                  labelText={
                    user && user.role === "partner"
                      ? t("companyName")
                      : t("signUp.part3")
                  }
                  type="text"
                  name="name"
                  value={
                    user && user.role === "partner"
                      ? user?.companyName
                      : user?.name
                  }
                  readOnly={true}
                  placeholder={t("signUp.part4")}
                  className={
                    currentLocalization === "ar"
                      ? "login-input-right-read-only"
                      : "login-input-left-read-only"
                  }
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={5} className="ms-md-3">
              <Form.Group as={Col} md="12" className="d-flex flex-column mt-3">
                <Input
                  labelClassName="label"
                  labelText={t("login.part3")}
                  type="email"
                  name="email"
                  value={user?.email}
                  readOnly={true}
                  placeholder={t("login.part4")}
                  className={
                    currentLocalization === "ar"
                      ? "login-input-right-read-only"
                      : "login-input-left-read-only"
                  }
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={5}>
              <Form.Group className="mt-3">
                <Input
                  labelClassName="label"
                  labelText={t("signUp.part5")}
                  type="text"
                  name="phoneNo"
                  value={
                    user && user.role === "partner" ? "-" : formData.phoneNo
                  }
                  onChange={handleChange}
                  className="login-input-left"
                />
                <div
                  className={
                    currentLocalization === "ar"
                      ? "text-end mt-2"
                      : "text-start mt-2"
                  }
                >
                  {validated && formData.phoneNo.length < 8 && (
                    <span className="text-danger">
                      {t("validation.phoneRequired")}
                    </span>
                  )}
                </div>
              </Form.Group>
            </Col>
            <Col xs={12} md={5} className="ms-md-3 mt-3">
              <MultiSelect
                onChange={handleCountryChange}
                name="countries"
                setFormInfo={setFormData}
                selectedOptions={selectedCountries}
                setSelectedOptions={setSelectedCountries}
                type="multiple"
                options={currencySymbols}
                label={t("signUp.part13")}
                labelClassName="label"
                labelText={t("signUp.part7")}
                currentLocalization={currentLocalization}
              />
              <div
                className={
                  currentLocalization === "ar"
                    ? "text-end mt-2"
                    : "text-start mt-2"
                }
              >
                {validated && selectedCountries.length === 0 && (
                  <span className="text-danger">
                    {t("validation.atLeastOneCountryRequired")}
                  </span>
                )}
              </div>
            </Col>
            <Col xs={12} md={5}>
              <Form.Group as={Col} md="12" className="d-flex flex-column mt-3">
                <PasswordInput
                  handleTogglePassword={handleTogglePassword}
                  label={t("login.part5")}
                  showPassword={showPassword}
                  name="password"
                  className={
                    currentLocalization === "ar"
                      ? "login-input-password-right"
                      : "login-input-password-left"
                  }
                  labelClassName="label"
                  placeholder={t("newPassword.part1")}
                  currentLocalization={currentLocalization}
                  value={formData.password}
                  onChange={handleChange}
                />
                <div
                  className={
                    currentLocalization === "ar"
                      ? "text-end mt-2"
                      : "text-start mt-2"
                  }
                >
                  <div
                    className={
                      currentLocalization === "ar"
                        ? "text-end mt-2"
                        : "text-start mt-2"
                    }
                  >
                    {passwordValidation && formData.password.length < 6 && (
                      <span className="text-danger">
                        {t("validation.passwordLength")}
                      </span>
                    )}
                  </div>
                </div>
              </Form.Group>
            </Col>
            <Col xs={12} md={5} className="ms-md-3">
              <Form.Group as={Col} md="12" className="d-flex flex-column mt-3">
                <PasswordInput
                  handleTogglePassword={handleToggleConfirmPassword}
                  label={t("newPassword.part4")}
                  showPassword={showConfirmPassword}
                  name="confirmpassword"
                  className={
                    currentLocalization === "ar"
                      ? "login-input-password-right"
                      : "login-input-password-left"
                  }
                  labelClassName="label"
                  placeholder={t("newPassword.part4")}
                  currentLocalization={currentLocalization}
                  value={formData.confirmpassword}
                  onChange={handleChange}
                />

                <div
                  className={
                    currentLocalization === "ar"
                      ? "text-end mt-2"
                      : "text-start mt-2"
                  }
                >
                  <div
                    className={
                      currentLocalization === "ar"
                        ? "text-end mt-2"
                        : "text-start mt-2"
                    }
                  >
                    {passwordValidation &&
                      formData.confirmpassword &&
                      formData.password !== formData.confirmpassword && (
                        <span className="text-danger">
                          {t("validation.matchPassword")}
                        </span>
                      )}
                  </div>
                </div>
              </Form.Group>
            </Col>
            <Col xs={12} md={5}>
              <Form.Group as={Col} md="12" className="d-flex flex-column mt-3">
                <label className="label">{t("signUp.part16")}</label>
                <div
                  className={
                    currentLocalization === "ar"
                      ? "login-input-right-referral"
                      : "login-input-left "
                  }
                >
                  <input
                    type="text"
                    name="referralLink"
                    value={referralLink}
                    readOnly={true}
                    placeholder={t("signUp.part16")}
                    className={
                      currentLocalization === "ar" ? "offdK-right" : "offdK"
                    }
                  />
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>{t("link copied")}</Tooltip>}
                    show={isCopied}
                  >
                    <button
                      className="copy-button"
                      onClick={handleCopy}
                      disabled={!referralLink}
                    >
                      {isCopied ? (
                        <LuCopyCheck size={30} />
                      ) : (
                        <LuCopy size={30} />
                      )}
                    </button>
                  </OverlayTrigger>
                </div>
              </Form.Group>
            </Col>
            <Col
              className="mt-5 ms-2"
              md={10}
              xs={11}
              style={{ border: "1px solid #D9D9D9" }}
            ></Col>
            <Col md={10} xs={11} className="mt-5 ms-2 p-0">
              <Row
                className={
                  currentLocalization === "ar"
                    ? "d-flex justify-content-start"
                    : "d-flex justify-content-end"
                }
              >
                <Col md={3} xs={11}>
                  <div className="d-grid gap-2">
                    <button className="profile-cancel" onClick={handleCancel}>
                      {t("dataUsage.Cancel")}
                    </button>
                  </div>
                </Col>
                <Col md={3} xs={11}>
                  <div className="d-grid gap-2">
                    <button
                      className="profile-save"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <span className="ms-2">{t("login.part16")} ...</span>
                        </>
                      ) : (
                        t("newPassword.part5")
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Container>
    </Sidebar>
  );
};

export default ProfileSetting;
