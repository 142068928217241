import axios from "axios";
import routes from "../../configs/api";
import { AUTH_TOKEN_KEY } from "../../constants/localstorageKeys";

const getTransactionsList = ({
  startDate = null,
  endDate = null,
  search = "",
  id = null,
}) => {
  return new Promise((resolve, reject) => {
    let URL = `${routes.GET_TRANSACTIONS_LIST}`;
    const token = localStorage.getItem(AUTH_TOKEN_KEY);
    const params = {};

    if (startDate && endDate) {
      params.startDate = new Date(startDate).toISOString().split("T")[0];
      params.endDate = new Date(endDate).toISOString().split("T")[0];
    }

    if (search) {
      params.search = search;
    }

    if (id) {
      params.id = id;
    }

    if (Object.keys(params).length > 0) {
      URL = `${URL}?${new URLSearchParams(params).toString()}`;
    }
    axios
      .get(URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default getTransactionsList;
