import axios from "axios";
import routes from "../../configs/api";

const setPartnerPassword = (data,token) => {
  return new Promise((resolve, reject) => {
    const URL = `${routes.SET_PARTNER_PASSWORD}/${token}`;
    axios
      .put(URL, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default setPartnerPassword;
