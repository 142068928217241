import React, { useState, useEffect } from 'react';
import ComingSoonSvg from "../../assets/images/comingsoon.svg";
import Spinner from "react-bootstrap/Spinner";
import Footer from "../../component/Footer/Footer";
import Navbars from "../../component/Navbar/Navbar";
import { Container } from "react-bootstrap";

function TermsAndConditions() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <div className="terms-and-conditions-page">
      <Navbars />
      <Container>
        {loading ? (
          <div className="w-100 pt-5 pb-5 text-center">
            <Spinner animation="border" variant="dark" />
          </div>
        ) : (
          <div className="text-center mt-5">
            <img
              src={ComingSoonSvg}
              alt="Coming Soon"
              width={800}
              height={500}
              style={{ borderRadius: "10px" }}
            />
          </div>
        )}
        <Footer />
      </Container>
    </div>
  );
}

export default TermsAndConditions;
