import React, { useContext, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RegisterSidebar from "../../component/RegisterSidebar";
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";
import Input from "../../component/Input";
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";
import { AuthUserContext } from "../../context/context";
import { Link, useNavigate } from "react-router-dom";
import forgetPassword from "../../services/auth/forgetPassword";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/esm/Spinner";
import authLogo from "../../assets/images/auth-logo.svg";

import LanguageSelect from "../../component/LanguageSelect";
const ResetPassword = ({setEventTriggered}) => {
  const { t } = useTranslation();
  const { currentLocalization, setToastId, toastId } = useContext(AuthUserContext);
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  const [emailValidation, setEmailValidation] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (!email) {
        setValidated(true);
      }
      setValidated(false);
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setEmailValidation(true);
        return;
      }
      let lang;
       if (currentLocalization.startsWith("en-")) {
        lang = "en";
      } else {
        lang = currentLocalization;
      }
      setLoading(true);
      setEmailValidation(false);
      const object = {
        email,
        lang: lang,
      };
      let response = await forgetPassword(object);
      if (response) {
        const id = toast.success(response?.message, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
      }
      navigate("/log-in");
    } catch (err) {
      if (err.response?.status === 404) {
        const id = toast.error(err.response?.data?.message, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
      } else {
        const id = toast.error(err?.message, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
      }
    } finally {
      setLoading(false);
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !loading) {
      if (toastId) setEventTriggered(true);
      event.preventDefault();
      handleSubmit(event);
    }
  };
  return (
    <Container fluid className="main-login-image">
      <Row className="row-login d-flex justify-content-center">
        <Col xs={11} md={6} className="loginImage p-0 ">
          <RegisterSidebar />
        </Col>
        <Col xs={12} md={6} className=" text-start login-side">
          <Row className="d-flex justify-content-center align-items-center pt-5">
            <Col
              md={9}
              className={`pt-0 p-md-0 p-4 d-flex align-items-center justify-content-between ${currentLocalization === "ar" && "flex-row-reverse"
                }`}
            >
              <Link to="/">
                <img src={authLogo} alt="" />
              </Link>
              <LanguageSelect />
            </Col>
          </Row>
          <Row className="d-flex justify-content-center align-items-center pt-5 pb-5 large-screen">
            <Col md={9} className="pt-0 p-md-0 p-4 ">
              <div
                style={{ marginTop: "20px" }}
                className={`p-0 ${currentLocalization === "ar" ? "text-end" : "text-start"
                  }`}
              >
                <h2 className="login-welcome">{t("resetPassword.part1")}</h2>
                <span className="login-information">
                  {t("resetPassword.part3")}
                </span>
                <Form
                  method="post"
                  onKeyDown={handleKeyDown}
                  onSubmit={handleSubmit}
                  className="mt-4"
                >
                  <Form.Group as={Col} md="12" className="d-flex flex-column">
                    <Input
                      labelClassName="label"
                      labelText={t("login.part3")}
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder={t("login.part4")}
                      className={
                        currentLocalization === "ar"
                          ? "login-input-right"
                          : "login-input-left"
                      }
                    />
                    <div
                      className={
                        currentLocalization === "ar"
                          ? "text-end mt-2"
                          : "text-start mt-2"
                      }
                    >
                      {validated && !email ? (
                        <span className="text-danger">
                          {t("validation.emailRequired")}
                        </span>
                      ) : (
                        emailValidation &&
                        !email && (
                          <span className="text-danger">
                            {t("validation.invalidEmail")}
                          </span>
                        )
                      )}
                    </div>
                  </Form.Group>

                  <Row style={{ marginTop: "25px" }}>
                    <Col>
                      <div className="d-grid gap-2 ">
                        <button
                          className={
                            loading
                              ? "btn-login-main-disabled"
                              : "btn-login-main"
                          }
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? (
                            <>
                              {currentLocalization === "ar" ? (
                                <>
                                  <span className="me-2">
                                    ... {t("login.part16")}
                                  </span>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                </>
                              ) : (
                                <>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                  <span className="ms-2">
                                    {t("login.part16")} ...
                                  </span>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {currentLocalization === "ar" ? (
                                <>
                                  <GrFormPreviousLink
                                    size={27}
                                    className="me-1"
                                  />
                                  {t("resetPassword.part2")}
                                </>
                              ) : (
                                <>
                                  {t("resetPassword.part2")}
                                  <GrFormNextLink size={27} className="ms-1" />
                                </>
                              )}
                            </>
                          )}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
                <div className="text-center" style={{ marginTop: "25px" }}>
                  <span className="dont-login">{t("signUp.part11")}</span>
                  <Link
                    to="/log-in"
                    style={{ textDecoration: "none" }}
                    onClick={() => window.scroll(0, 0)}
                  >
                    <span className="dont-login-today">
                      &nbsp;{t("signUp.part12")}
                    </span>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPassword;
