import React, { useContext } from "react";
import Spinner from "react-bootstrap/esm/Spinner";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { AuthUserContext } from "../context/context";
const ListingDataTables = ({
  columns,
  data,
  loading,
  totalRows,
  perPage,
  onChangePage,
  onChangeRowsPerPage,
  handleSort,
  handleSelectedRowsChange,
  toggledClearRows,
}) => {
  const { t } = useTranslation();
  const { currentLocalization } = useContext(AuthUserContext);
  const rowsPerPageOptions = [20, 50, 100];

  return (
    <div className="datatable-width-set">
      <DataTable
        columns={columns}
        data={data}
        fixedHeaderScrollHeight="300px"
        highlightOnHover
        noContextMenu
        responsive={true}
        selectableRows
        pagination
        paginationServer
        subHeaderAlign="center"
        paginationTotalRows={totalRows}
        paginationPerPage={perPage}
        onChangePage={onChangePage}
        paginationRowsPerPageOptions={rowsPerPageOptions}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onSelectedRowsChange={handleSelectedRowsChange}
        clearSelectedRows={toggledClearRows}
        onSort={handleSort}
        direction={currentLocalization === "ar" ? "rtl" : "ltr"}
        progressPending={loading}
        progressComponent={
          loading ? (
            <div
              className=" d-flex justify-content-center align-items-center"
              style={{ height: "400px" }}
            >
              <Spinner animation="border" />
            </div>
          ) : (
            <div
              className=" d-flex justify-content-center align-items-center border"
              style={{ height: "400px" }}
            >
              {t("dataUsage.noRecordsFound")}
            </div>
          )
        }
        noDataComponent={
          loading ? (
            <div
              className="w-100 d-flex justify-content-center align-items-center"
              style={{ height: "400px" }}
            >
              <Spinner animation="border" />
            </div>
          ) : (
            <div
              className="w-100 d-flex justify-content-center align-items-center"
              style={{ height: "400px" }}
            >
              {t("dataUsage.noRecordsFound")}
            </div>
          )
        }
      />
    </div>
  );
};

export default ListingDataTables;
