"use client";
import React, { useState, useRef, useEffect } from "react";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import MenuItem from "@mui/material/MenuItem";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import { MdMoreHoriz } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function PartnerDatatableMenu({ row, onDelete }) {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState();
  const ref = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref]);
  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };
  return (
    <div ref={ref} className="partner-menu">
      <MdMoreHoriz
        className="keyboardArrowDownIcon-color"
        onClick={handleClick}
        size={35}
      />
      <Overlay
        show={show}
        target={target}
        placement="left"
        container={ref}
        containerPadding={20}
      >
        <Popover id="popover-contained-partner">
          <Popover.Body>
            <MenuItem
              onClick={() => {
                navigate(`/view-partner/${row._id}`);
                setShow(false);
              }}
              className="menu-datatable-text"
            >
              <RemoveRedEyeIcon className="me-2" fontSize="small" />
              View
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate(`/edit-partner/${row._id}`);
                setShow(false);
              }}
              className="menu-datatable-text"
            >
              <BorderColorIcon className="me-2" fontSize="small" /> Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                onDelete(row._id, row?.companyName);

                setShow(false);
              }}
              className="menu-datatable-text"
            >
              <DeleteIcon className="me-2" fontSize="small" /> Delete
            </MenuItem>
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
}

export default PartnerDatatableMenu;
