import React, { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import OfferCard from "../../component/OfferCard";
import Footer from "../../component/Footer/Footer";
import { images } from "../../utils/image";
import { useTranslation } from "react-i18next";
import CurrencyNameSelect from "../../component/CurrencyNameSelect";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import getEsimLists from "../../services/api/eSim/getEsimDetails";
import Spinner from "react-bootstrap/Spinner";
import { AuthUserContext } from "../../context/context";
import HomeSelect from "../../component/HomeSelect";
import Navbars from "../../component/Navbar/Navbar";
import {
  countryCode,
  countryCurrency,
  countryList,
  currencySymbols,
} from "../../constants/currency";
import {
  COUNTRY_CURRENCY,
  CURRENCY,
  USER_DETAILS,
} from "../../constants/localstorageKeys";
import currencyExchange from "../../services/api/currencyyExchange";
import { useLocation } from "react-router-dom";
import SectionMode from "../../component/SectionMode";
import getLiveSections from "../../services/api/Sections/getLiveSections";
const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [eSimDetails, setEsimDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [exchangeRate, setExchangeRate] = useState(1);
  const [currencySymbol, setCurrencySymbol] = useState("$");
  const currency = localStorage.getItem(CURRENCY);
  const [selectedCountry, setSelectedCountry] = useState();
  const [sections, setSections] = useState([]);
  const [initialized, setInitialized] = useState(true);
  const location = useLocation();
  const [sectionModeLoading, setSectionModeLoading] = useState(false);
  const {
    currentLocalization,
    setPackageType,
    setLinkCopy,
    user,
    setUser,
    setTopUpLink,
    setToastId,
  } = useContext(AuthUserContext);
  const [selectedCurrency, setSelectedCurrency] = useState("USD");
  const getEsimList = async () => {
    try {
      setLoading(true);
      let langs;
      if (currentLocalization === "en-US") {
        langs = "en";
      } else {
        langs = currentLocalization;
      }
      const response = await getEsimLists("", "", "", "", "", langs);
      setEsimDetails(response?.data);
    } catch (err) {
      const id = toast.error(err.response?.data?.message || err.message, {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        pauseOnHover: true,
      });
      setToastId(id);
    } finally {
      setLoading(false);
    }
  };
  const handleCurrencyChange = ({ exchangeRate, currency }) => {
    setExchangeRate(exchangeRate);
    setCurrencySymbol(currency);
  };
  const selectCountrys = () => {
    if (selectedCountry) {
      const country = countryCode.find(
        (country) => country.countryName === selectedCountry
      );
      const currency = country ? country.countryCurrency : "USD";
      localStorage.setItem(COUNTRY_CURRENCY, currency);
      navigate(`/country-filter?country=${selectedCountry}`);
      window.scroll(0, 0);
    }
  };
  const currencyCHange = async () => {
    try {
      if (currency) {
        const getCountryCurrency = countryCurrency.find(
          (code) => code.currency_code === currency
        );
        const country = countryCode.find(
          (country) => country.countryName === getCountryCurrency.country_code
        );
        const newCurrency = country ? country.countryCurrency : "USD";
        const currencyCode =
          currencySymbols.find((symbol) => symbol.Code === newCurrency)
            ?.Symbol ?? currency;
        setCurrencySymbol(currencyCode);
        setSelectedCurrency(newCurrency);
        const response = await currencyExchange();
        const exchangeRate = response.rates[newCurrency];
        setExchangeRate(exchangeRate);
      }
    } catch (e) {
      console.log("e", e);
    }
  };
  useEffect(() => {
    currencyCHange();
  }, [currency]);
  useEffect(() => {
    selectCountrys();
  }, [selectedCountry]);
  useEffect(() => {
    getEsimList();
    setPackageType("");
  }, [currentLocalization]);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const referrer = params.get('partner');
    if (referrer) {
      const normalizedReferrer = referrer.replace(/^https?:\/\//, '').replace(/^www\./, '');

      localStorage.setItem('referrer', normalizedReferrer);
      params.delete('partner');
      navigate("/country-filter?country=SA");

      const newUrl = params.toString()
        ? `${window.location.pathname}?${params.toString()}`
        : window.location.pathname;

      window.history.replaceState({}, '', newUrl);
    }
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // localStorage.setItem(CURRENCY, "USD");
        setLinkCopy("");
        setTopUpLink("");
        setSectionModeLoading(true);
        const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
        const response = await getLiveSections(
          "liveMode",
          "Home",
          currentLocalization
        );
        setSections(response.data);

        if (userDetails) {
          setUser(userDetails);
        }
        setInitialized(false);
      } catch (error) {
        console.error("Error fetching live sections:", error);
        setInitialized(false);
      } finally {
        setSectionModeLoading(false);
      }
    };

    fetchData();
  }, [currentLocalization]);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    localStorage.removeItem("OUTSIDER_USER");
    if (params.get("payment_success")) {
      const id = toast.success(t("toastsMessage.part1"), {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        pauseOnHover: true
      });
      setToastId(id);
      params.delete("payment_success");
      navigate(location.pathname, { replace: true });
    }
  }, [location.search, location.pathname, navigate]);
  return (
    <div>
      <Navbars />

      {!initialized && (
        <>
          {user.length !== 0 ? (
            <></>
          ) : (
            <Container fluid>
              <Row className="home-headline">
                <Col
                  className={`d-flex justify-content-center align-items-center ${currentLocalization == "ar"
                      ? "flex-row-reverse"
                      : "flex-row"
                    }`}
                >
                  <span
                    className={`${currentLocalization == "in"
                        ? "bannerFont-in"
                        : "bannerFont"
                      } `}
                  >
                    {t("login.part21")}
                  </span>
                  <span
                    className={
                      currentLocalization == "in"
                        ? "discount-text-span-ar"
                        : "discount-text-span"
                    }
                  >
                    &nbsp;{t("login.part22")}
                  </span>
                  &nbsp;&nbsp;
                  <span
                    className={
                      currentLocalization == "in"
                        ? "bannerFont-in-link"
                        : "bannerFont-link"
                    }
                    to=""
                    onClick={() => {
                      navigate("/sign-up");
                      window.scroll(0, 0);
                    }}
                  >
                    {t("login.part23")}
                  </span>
                </Col>
              </Row>
            </Container>
          )}
        </>
      )}

      <section className="home-image">
        <Container>
          <Row className="menu-padding d-flex justify-content-center align-items-center ">
            <Col md={12} xs={12} className="menu-box p-md-5">
              <Row
                className={
                  currentLocalization == "ar"
                    ? "d-flex flex-row-reverse align-items-center p-5"
                    : "d-flex flex-row align-items-center"
                }
              >
                <Col
                  lg={7}
                  md={8}
                  xs={12}
                  className={
                    currentLocalization == "ar"
                      ? "text-end p-3"
                      : "text-start p-3"
                  }
                >
                  <span className="menu-box-span">
                    <img src={images.line} alt="line" />
                    &nbsp;&nbsp;&nbsp; {t("home.part1")}
                  </span>
                  <p className="menu-box-p-unleash m-0">{t("home.part2")}</p>
                  <h3 className="menu-box-p-technology m-0">
                    {t("home.part3")}
                    <span style={{ color: "#2AFC9E" }}> {t("home.part4")}</span>
                    <span style={{ color: "#fff" }}>{t("home.part5")}</span>
                  </h3>
                  <h3 className="menu-box-p-technology">{t("home.part6")}</h3>
                  <Row
                    className={
                      currentLocalization == "ar"
                        ? "d-flex justify-content-end"
                        : ""
                    }
                  >
                    <Col md={10} xs={12} className="pt-2">
                      <label className="menu-box-label-country mb-3">
                        {t("home.part7")}
                      </label>
                      <HomeSelect setSelectedCountry={setSelectedCountry} />
                    </Col>
                  </Row>
                </Col>
                <Col lg={5} md={4} xs={12}>
                  {currentLocalization == "ar" ? (
                    <img
                      src={images.esimAr}
                      alt="mobile"
                      className="img-fluid"
                      height={455}
                    />
                  ) : currentLocalization == "fr" ? (
                    <img
                      src={images.esimFr}
                      alt="mobile"
                      className="img-fluid"
                      height={455}
                    />
                  ) : currentLocalization == "in" ? (
                    <img
                      src={images.esimIn}
                      alt="mobile"
                      className="img-fluid"
                      height={455}
                    />
                  ) : (
                    <img
                      src={images.mobile}
                      alt="mobile"
                      className="img-fluid"
                      height={455}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pt-5 country-image-box">
        <Container>
          <Row className="d-flex justify-content-center">
            <div className="d-flex justify-content-center">
              <Col
                lg={
                  currentLocalization == "fr" || currentLocalization == "in"
                    ? 8
                    : 6
                }
                md={4}
                xs={12}
              >
                <h2 className="popular-h2">
                  {t("home.part9")}
                  <span style={{ color: " #004940" }}>{t("home.part29")}</span>
                </h2>
              </Col>
            </div>
            <Col
              lg={
                currentLocalization == "fr" || currentLocalization == "in"
                  ? 9
                  : 7
              }
              md={5}
              xs={12}
            >
              <span className="home-time-span">{t("home.part10")}</span>
              <br />
              <span className="home-time-span">{t("home.part31")}</span>
            </Col>
            <Row className="d-flex justify-content-center">
              <Col md={12} className="grid-box mt-5 mb-4">
                {countryList?.map((item, index) => {
                  return (
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`/country-filter?country=${item?.countryCode}`}
                      onClick={() => {
                        window.scroll(0, 0);
                        localStorage.setItem(COUNTRY_CURRENCY, item?.currency);
                      }}
                    >
                      <div className="country-box m-2 mb-4" key={index}>
                        <img
                          src={item?.flag}
                          alt="flag"
                          className="country-image"
                        />
                        <span className="country-name-span mt-3">
                          {t(`countryName.${item?.countryName}`)}
                        </span>
                      </div>
                    </Link>
                  );
                })}
              </Col>
            </Row>

            <Col xs={12} className="">
              <button
                className="view-btn mb-5"
                onClick={() => {
                  navigate(`/country-filter?country=SA`);
                  localStorage.setItem(COUNTRY_CURRENCY, "SAR");
                  window.scroll(0, 0);
                }}
              >
                {t("home.part11")}
              </button>
            </Col>
          </Row>
        </Container>

      </section>
      {sectionModeLoading ? (
        <div className="w-100 pt-5 pb-5">
          <Spinner animation="border" variant="dark" />
        </div>
      ) : (
        <div className={sections.length > 0 && "mt-5 mb-5"}>
          <SectionMode allSectionWithLang={sections} />
        </div>
      )}

      <section className="offers-image">
        <Container>
          <Row className="d-flex justify-content-center pt-5 m-0">
            <Col lg={11} md={10} xs={12} className="pt-3 text-padding-left">
              <h2 className="popular-h2">
                {t("home.part12")}
                &nbsp;
                <span style={{ color: " #004940" }}>{t("home.part30")}</span>
              </h2>
              <span className="home-time-span">{t("home.part13")}</span>
              <br />
              <span className="home-time-span">{t("home.part17")}</span>
            </Col>
            <Col
              lg={1}
              md={2}
              xs={6}
              className="mt-md-0 mt-3 p-0 pe-3 d-flex justify-content-center align-items-end"
            >
              <CurrencyNameSelect
                onCurrencyChange={handleCurrencyChange}
                selectedCurrency={selectedCurrency}
                setSelectedCurrency={setSelectedCurrency}
                value="home-main"
              />
            </Col>
          </Row>
          <Row className="mt-5 ">
            <Col xs={12} className="d-flex flex-wrap justify-content-between">
              {loading ? (
                <div className="w-100 pt-5 pb-5">
                  <Spinner animation="border" variant="dark" />
                </div>
              ) : eSimDetails?.length > 0 ? (
                <>
                  {eSimDetails
                    ?.slice(eSimDetails?.length - 6, eSimDetails?.length)
                    .map((item, index) => {
                      return (
                        <OfferCard
                          item={item}
                          index={index}
                          offers={eSimDetails}
                          exchangeRate={exchangeRate}
                          user={user}
                          currencySymbol={currencySymbol}
                          previewMode=""
                        />
                      );
                    })}
                </>
              ) : (
                <div className="w-100 pt-5 pb-5">
                  <span>{t("home.part28")}</span>
                </div>
              )}
            </Col>
          </Row>
          <Col xs={12}>
            <button
              className={loading ? "view-btn-disabled mb-5" : "view-btn mb-5"}
              onClick={() => {
                navigate(`/country-filter?country=SA`);
                localStorage.setItem(COUNTRY_CURRENCY, "SAR");
                window.scroll(0, 0);
              }}
              disabled={loading}
            >
              {t("home.part11")}
            </button>
          </Col>

          <Footer />
        </Container>
      </section>
    </div>
  );
};

export default Home;
