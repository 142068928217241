import React, { useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import { AuthUserContext } from "../context/context";
import svg from "../assets/images/login-esim.svg";
const RegisterSidebar = () => {
  const { t } = useTranslation();
  const { currentLocalization } = useContext(AuthUserContext);
  return (
    <Container>
      <Row className="d-flex justify-content-center align-items-center pt-5 pb-5">
        <Col xs={12} md={10} lg={10} xl={10} xxl={10} className="padding-set ">
          <div className="down-image-box m-2">
            <div
              className={`down-image ${
                currentLocalization === "ar"
                  ? "text-end sideba-login-padding"
                  : "text-start"
              }`}
            >
              <h1 className={currentLocalization === "in" ? "boost-h1-in" : "boost-h1"}>
                {t("login.part12")}
                <br />
                {t("login.part15")}
              </h1>
              <h1 className={currentLocalization === "in" ? "boost-h1-esim-in" : "boost-h1-esim"}>
                {t("login.part13")}
                <br />
                {t("login.part14")}
              </h1>
            </div>
          </div>
          <div className="pt-5">
            <img src={svg} alt="esim" width="100%" />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default RegisterSidebar;
