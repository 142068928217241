import axios from "axios";
import routes from "../../configs/api";
import { AUTH_TOKEN_KEY } from "../../constants/localstorageKeys";

const updatePartner = (data,id) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY);
    const URL = `${routes.UPDATE_PARTNER}/${id}`;
    axios
      .put(URL, data, 
        {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default updatePartner;