import React, { useContext } from "react";
import { TbWorld } from "react-icons/tb";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AuthUserContext } from "../context/context";
import esimVaultSvg from "../assets/images/esimVault.svg";
import airaloSvg from "../assets/images/airalo.svg";
import esimgoSvg from "../assets/images/esimgo.svg";
const OfferCard = ({
  item,
  index,
  offers,
  exchangeRate = 1,
  currencySymbol = "$",
  user,
  previewMode,
}) => {
  const { t } = useTranslation();
  const { currentLocalization } = useContext(AuthUserContext);
  const navigate = useNavigate();
  const specialIndices = [];
  for (let i = 1; i < offers.length; i += 3) {
    specialIndices.push(i);
  }
  return (
    <>
      <Col
        md={4}
        xs={12}
        className={
          specialIndices.includes(index) === true
            ? "offer-card-box-yellow mb-4 p-0 m-2"
            : "offer-card-box-green mb-4 p-0 m-2"
        }
        key={index}
      >
        <Col
          md={12}
          xs={12}
          className={
            specialIndices.includes(index) === true
              ? "offer-card-box-top-yellow  offer-card-top-box"
              : "offer-card-box-top-green offer-card-top-box"
          }
        >
          <div>
            <Row
              className={
                currentLocalization === "ar"
                  ? " d-flex flex-row-reverse"
                  : "d-flex flex-row"
              }
            >
              <Col
                md={12}
                xs={12}
                className={`d-flex align-items-start ms-2 ${
                  currentLocalization === "ar" ? "flex-row-reverse" : "flex-row"
                }`}
                style={{ height: "60px" }}
              >
                <div className="mt-2">
                  <img
                    src={
                      item?.key === "esimgo"
                        ? esimgoSvg
                        : item?.key === "esimVault"
                        ? esimVaultSvg
                        : airaloSvg
                    }
                    alt="offer-icon"
                    width={50}
                  />
                </div>
                <h3
                  className={`global-h3 mt-3 ${
                    currentLocalization === "ar"
                      ? "me-2 text-end ms-2"
                      : "ms-2 me-2 text-start"
                  }`}
                >
                  {item?.name}
                </h3>
              </Col>
              <Row className=" ms-1 mt-md-0 mt-4" style={{ height: "60px" }}>
                <Col
                  md={3}
                  xs={4}
                  className={`d-flex flex-column p-0 mb-2 ${
                    currentLocalization === "ar"
                      ? "text-end pe-4 border-line"
                      : "text-start border-line"
                  }`}
                >
                  <span className="offer-validity-span p-0">
                    {t("home.part18")}
                  </span>
                  {currentLocalization === "ar" ? (
                    <div className="d-flex align-items-center justify-content-end">
                      <span className="offer-days-span">
                        {t("home.part21")}
                      </span>
                      <span> &nbsp;{item?.validity}</span>
                    </div>
                  ) : (
                    <span className="offer-days-span">
                      {item?.validity} {t("home.part21")}
                    </span>
                  )}
                </Col>
                <Col
                  md={4}
                  xs={4}
                  className={`d-flex flex-column  mb-2 ${
                    currentLocalization === "ar"
                      ? "text-end pe-3 border-padding-data"
                      : currentLocalization === "fr"
                      ? "text-start border-padding-offerCard-data-fr"
                      : "text-start border-padding-data"
                  }`}
                  style={{ borderRight: "1px solid #DEDEDE" }}
                >
                  <span className="offer-validity-span ms-md-3 p-0">
                    {t("home.part19")}
                  </span>
                  {currentLocalization === "ar" ? (
                    <div className="d-flex flex-row align-items-center justify-content-end">
                      <span className="offer-days-span">
                        {t("home.part22")}
                      </span>
                      <span> &nbsp;{item?.data}</span>
                    </div>
                  ) : (
                    <span className="offer-days-span ms-md-3">
                      {item?.data} {t("home.part22")}
                    </span>
                  )}
                </Col>
                <Col
                  md={5}
                  xs={4}
                  className={`d-flex flex-column border-padding mb-2 ${
                    currentLocalization === "ar"
                      ? "text-end pe-xl-1 "
                      : "text-start "
                  }`}
                >
                  <span className="offer-validity-span  p-0">
                    {t("home.part20")}
                  </span>
                  <span
                    className={`offer-days-span-filter ${
                      user && user.parent_id ? "" : "price-offer"
                    }`}
                  >
                    {currencySymbol || "$"}&nbsp;
                    {(item?.price * exchangeRate).toFixed(2)}
                  </span>
                </Col>
              </Row>
              <div
                className={`${user && user.parent_id ? "" : "cards-discount"}`}
              >
                {user && user.parent_id ? null : (
                  <Row style={{ minHeight: "30px" }}>
                    <Col
                      md={7}
                      xs={8}
                      className={`d-flex flex-column m-0 ${
                        currentLocalization === "ar"
                          ? " justify-content-center text-end pe-4 border-line discount-box-right-dashbaord"
                          : "text-start border-line discount-box-dashboard justify-content-center"
                      }`}
                    >
                      <span className="offer-validity-span offercard-discount ">
                        {t("discountedOffer")}
                      </span>
                    </Col>
                    <Col
                      md={5}
                      xs={4}
                      className={`d-flex flex-column ${
                        currentLocalization === "ar"
                          ? "justify-content-center text-end pe-xl-1  discount-4-box-right-dashboard"
                          : "text-start  justify-content-center discount-4-box"
                      }`}
                    >
                      <span
                        className={
                          currentLocalization === "ar"
                            ? " price-padding"
                            : "offer-days-span pt-1 dashboard-padding"
                        }
                      >
                        {currencySymbol}&nbsp;
                        {(item?.discountedPrice * exchangeRate).toFixed(2)}
                      </span>
                    </Col>
                  </Row>
                )}
              </div>

              <Col
                md={12}
                xs={12}
                style={{ height: "60px" }}
                className=" pt-2 p-0"
              >
                <ul className=" p-0">
                  <li
                    className={` d-flex  ${
                      currentLocalization === "ar"
                        ? "text-end pe-1 flex-row-reverse align-items-start"
                        : "text-start align-items-start ps-3 pe-1"
                    }`}
                  >
                    <span>
                      <TbWorld size={20} color="#004940" />
                    </span>
                    <span
                      className={`offers-works-span-filter ${
                        currentLocalization === "ar" ? "pe-1" : "ps-1"
                      }`}
                    >
                      {t("countryFilter.part31")}
                      <span class="getCountryss-bold">
                        &nbsp;{t(`countryName.${item?.country_name}`)}
                      </span>
                      &nbsp;{" "}
                      {item?.countries_count
                        ? ` & ${item?.countries_count - 1} ${t(
                            "countryFilter.part32"
                          )}`
                        : ""}
                    </span>
                  </li>
                </ul>
              </Col>
            </Row>
            <Col md={12} xs={12} className={`text-start p-2 `}>
              <div className="d-grid">
                <button
                  className="view-offer-button"
                  size="lg"
                  onClick={() => {
                    navigate(
                      `/offer-detail?id=${item?.orignalName}&key=${item?.key}`
                    );
                    window.scroll(0, 0);
                  }}
                  disabled={previewMode === "previewMode"}
                >
                  {t("home.part23")}
                </button>
              </div>
            </Col>
          </div>
        </Col>
      </Col>
    </>
  );
};

export default OfferCard;
