import React, { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Sidebar from "../../../component/Sidebar/SideBar";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import getPartnerById from "../../../services/partner/getPartnerById";
import { AuthUserContext } from "../../../context/context";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";
import { Box } from "@mui/material";
import { Bar, Line } from "react-chartjs-2";
import getUsersCardData from "../../../services/packages/getUsersCardData";
import { GoDotFill } from "react-icons/go";
import DateFilter from "../../../component/DateFilter";
import ApplyFilterButton from "../../../component/ApplyFilter";

const ChartWrapper = ({ children }) => (
  <Box sx={{ width: "100%", height: 500, margin: "0 auto" }}>{children}</Box>
);

const ViewPartner = () => {
  const { currentLocalization, setToastId } = useContext(AuthUserContext);
  const { id } = useParams();
  const [monthlySalesCommision, setmonthlySalesCommision] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [partner, setPartner] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [appliedStartDate, setAppliedStartDate] = useState('');
  const [appliedEndDate, setAppliedEndDate] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  const barData = {
    labels: monthlySalesCommision.map((item) => {
      const [year, month] = item.month.split("-");
      const date = new Date(year, month - 1);
      return date.toLocaleString("default", { month: "long", year: "numeric" });
    }),
    datasets: [
      {
        label: "Total Sales",
        data: monthlySalesCommision.map((item) => parseFloat(item.totalPrice)),
        backgroundColor: "#073d38",
      },
      {
        label: "Commission",
        data: monthlySalesCommision.map((item) => parseFloat(item.commission)),
        backgroundColor: "#04e784",
      },
    ],
  };
  const getPartnerId = async () => {
    try {
      const response = await getPartnerById(id);
      setPartner(response?.data);
    } catch (err) {
      const userDetails = JSON.parse(localStorage.getItem("USER_DETAILS"));
      const userRole = userDetails?.role;
      if (userRole === "member") {
        navigate("/content-management");
        return;
      }
      if (userRole === "user") {
        navigate("/data-usage");
        return;
      }
      if (err.response?.status === 401) {
        const id = toast.error(t("toastsMessage.part4"), {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true,
        });
        setToastId(id);
        window.scroll(0, 0);
        localStorage.clear();
        navigate("/log-in");
      } else {
        console.log("err", err);
        const id = toast.error(err.message, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true,
        });
        setToastId(id);
      }
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    getPartnerId();
  }, []);

  const fetchData = async (startDate = null, endDate = null) => {
    setDataLoading(true);
    try {
      const [usersResponse] = await Promise.all([
        getUsersCardData({ startDate, endDate, id }),
      ]);

      if (usersResponse.success) {
        setmonthlySalesCommision(usersResponse.data.monthlySalesCommision);
        setStartDate(usersResponse.data.startDate);
        setEndDate(usersResponse.data.endDate);
        if (!appliedStartDate && !appliedEndDate) {
          setStartDate(usersResponse.data.startDate);
          setEndDate(usersResponse.data.endDate);
        }
      }
    } catch (err) {
      console.error(err.message);
    } finally {
      setDataLoading(false);
    }
  };
  useEffect(() => {
    fetchData(appliedStartDate, appliedEndDate);
  }, [appliedStartDate, appliedEndDate]);

  const handleApplyFilter = () => {
    setAppliedStartDate(startDate);
    setAppliedEndDate(endDate);
  };
  return (
    <Sidebar>
      {dataLoading ? (
        <div className="spinner-setting">
          <Spinner animation="border" />
        </div>
      ) : (
        <div className="">
         <Row className="d-flex justify-content-between align-items-center p-2 package-h2">
            <Col md={8} className="text-start package-h2 p-0 mt-0 mt-3">
              <h2>{t("partnerListing.viewPartner")}</h2>
            </Col>
            <Col md={4} className="d-flex justify-content-end p-0 mt-3">
              <button
                className="cancel-partner-button"
                onClick={() => navigate("/partner-listing")}
              >
                Back
              </button>
              <button
                className="add-partner-button ms-2"
                onClick={() => navigate(`/edit-partner/${id}`)}
              >
                Edit
              </button>
            </Col>
          </Row>
          <div className="mt-5 ms-md-3">
            <div className="main-dashboard">
              <div className="image-box">
                <img src={partner?.companyLogo} alt="user_person" />
              </div>
              <div className="white-box">
                <Row className="d-flex justify-content-between flex-wrap">
                  <Col
                    xs={12}
                    sm={6}
                    lg={6}
                    md={6}
                    xl={3}
                    className="d-flex flex-column text-start mb-3"
                  >
                    <span className="company-text-span">
                      {t("partnerListing.companyName")}
                    </span>
                    <span className="company-text-span-name mt-2">
                      {partner?.companyName}
                    </span>
                  </Col>
                  <Col
                    xs={12}
                    sm={6}
                    lg={6}
                    md={6}
                    xl={3}
                    className="d-flex flex-column text-start mb-3"
                  >
                    <span className="company-text-span">
                      {t("partnerListing.companyEmail")}
                    </span>
                    <span className="company-text-span-name mt-2">
                      {partner?.email}
                    </span>
                  </Col>
                  <Col
                    xs={12}
                    sm={6}
                    lg={6}
                    md={6}
                    xl={3}
                    className="d-flex flex-column text-start mb-3"
                  >
                    <span className="company-text-span">
                      {t("partnerListing.companyDomain")}
                    </span>
                    <span className="company-text-span-name mt-2">
                      {partner?.domain}
                    </span>
                  </Col>
                  <Col
                    xs={12}
                    sm={6}
                    lg={6}
                    md={6}
                    xl={3}
                    className="d-flex flex-column text-start mb-3"
                  >
                    <span className="company-text-span">
                      {t("partnerListing.commissionPercentage")}
                    </span>
                    <span className="company-text-span-name mt-2">
                      <strong>{partner?.commissionPercentage}%</strong> at{" "}
                      <strong>{partner?.commissionAmount}$</strong> Minimum Sale
                    </span>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
            <Row className="mt-4">
              <div className="d-flex justify-content-end">
            <DateFilter 
            setStartDate={setStartDate} 
            setEndDate={setEndDate} 
            initialStartDate={startDate} 
            initialEndDate={endDate} 
          />
          <ApplyFilterButton onApply={handleApplyFilter} />
          </div>
              <Col>
                <Box sx={{}}>
                  <Box
                    sx={{
                      mt: 3,
                      p: 2,
                      borderRadius: 2,
                      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                      backgroundColor: "#ffffff",
                    }}
                  >
              <Row>
                <Col>
                  <div
                    className={
                      currentLocalization === "ar"
                        ? "text-end d-flex justify-content-between align-items-center mb-3 flex-row-reverse ps-2 pe-2 flex-wrap"
                        : "text-start d-flex justify-content-between align-items-center mb-3 ps-2 pe-2 flex-wrap"
                    }
                  >
                    <h2 className="package-h4 mt-2">
                      {t("Total Sales & Commission")}
                    </h2>
                    <div className="d-flex gap-3 mt-2">
                      <div>
                        <GoDotFill size={25} color="#054940" />
                        <span className="buy-package-span">
                          {t("Total Sales")}
                        </span>
                      </div>
                      <div>
                        <GoDotFill size={25} color="#00E886" />
                        <span className="buy-package-span">
                          {t("Total Commission")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      currentLocalization === "ar" ? "text-end" : "text-start"
                    }
                  ></div>
                </Col>
              </Row>

                    <ChartWrapper>
                      <Bar
                        data={barData}
                        options={{
                          responsive: true,
                          maintainAspectRatio: false,
                          plugins: {
                            legend: { display: false },
                            tooltip: {
                              enabled: true,
                              mode: "nearest",
                              intersect: false,
                              callbacks: {
                                label: function (tooltipItem) {
                                  const totalSales =
                                    tooltipItem.chart.data.datasets[0].data[
                                      tooltipItem.dataIndex
                                    ];
                                  const commission =
                                    tooltipItem.chart.data.datasets[1].data[
                                      tooltipItem.dataIndex
                                    ];

                                  return [
                                    `Total Sales: $${totalSales.toFixed(2)}`,
                                    `Commission: $${commission.toFixed(2)}`,
                                  ];
                                },
                                labelColor: function (tooltipItem, chart) {
                                  return {
                                    borderColor:
                                      tooltipItem.datasetIndex === 0
                                        ? "#04e784"
                                        : "#073d38",
                                    backgroundColor:
                                      tooltipItem.datasetIndex === 0
                                        ? "#04e784"
                                        : "#073d38",
                                  };
                                },
                              },
                            },
                          },
                          elements: {
                            bar: {
                              borderWidth: 2,
                              borderColor: "#ffffff",
                            },
                          },
                          scales: {
                            y: {
                              beginAtZero: true,
                              ticks: {
                                color: "#9e9e9e",
                                callback: function (value) {
                                  return `$${value}`;
                                },
                              },
                              grid: {
                                color: "#e0e0e0",
                              },
                            },
                            x: {
                              ticks: {
                                color: "#9e9e9e",
                              },
                              grid: {
                                display: false,
                              },
                            },
                          },
                        }}
                      />
                    </ChartWrapper>
                  </Box>
                </Box>
              </Col>
            </Row>
        </div>
      )}
    </Sidebar>
  );
};

export default ViewPartner;
