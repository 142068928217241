import axios from "axios";
import { AUTH_TOKEN_KEY } from "../../constants/localstorageKeys";
import routes from "../../configs/api";

const getUsersCardData = ({ startDate = null, endDate = null, id = null }) => {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);

  let URL = `${routes.USER_CARD_DATA}`;
  const params = {};

  if (startDate && endDate) {
    params.startDate = startDate;
    params.endDate = endDate;
  }
  if (id) {
    params.id = id;
  }

  if (Object.keys(params).length > 0) {
    URL = `${URL}?${new URLSearchParams(params).toString()}`;
  }

  return new Promise((resolve, reject) => {
    axios
      .get(URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default getUsersCardData;
